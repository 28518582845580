import React,{useContext, useState} from "react";
import Unavailability from "../Unavailabilty";
import { carUnavailbilty, unavailablePeriod } from "../../../api";
import { DataContext } from "../../../ContextApi";

const EditUnavailableDates = ({id}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [unavailableRanges, setUnavailableRanges] = useState([]);
const {openDialog}=useContext(DataContext)
    const convertDate = (newDateString) => {
        const newDate = new Date(newDateString);
        return newDate.toISOString().replace("T", " ").split(".")[0];
      };
    
      const handleSaveUnavailable = async () => {
        if (startDate && endDate) {
          let payload = {
            vehicle_id: id,
            start_time: convertDate(startDate),
            end_time: convertDate(endDate),
          };
          try {
            const response = await carUnavailbilty(payload);
            setStartDate(null);
            setEndDate(null);
            openDialog(response?.data?.message);
            unavailablePeriod(id, setUnavailableRanges);
          } catch (error) {
            console.error(
              "Error uploading file:",
              error.response ? error.response.data : error.message
            );
          }
        }
      };
    
      const highlightUnavailableDates = (date) => {
        return unavailableRanges?.some(
          (range) =>
            new Date(date) >= new Date(range.start) &&
            new Date(date) <= new Date(range.end)
        )
          ? "highlighted-date"
          : "";
      };
  
    
  return (
  
<div style={{ width: "100%" }}>
                           

                            <Unavailability
                              startDate={startDate}
                              setStartDate={setStartDate}
                              endDate={endDate}
                              setEndDate={setEndDate}
                              handleSaveUnavailable={handleSaveUnavailable}
                              highlightUnavailableDates={
                                highlightUnavailableDates
                              }
                            />
                          </div>

);
};

export default EditUnavailableDates;
