import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetail } from "../api";
import { DataContext } from "../ContextApi";
import logo from "../image/png/logo.svg";

// import character from "../../.././image/png/character_with.svg"
const ViewProfile = () => {
  const { openDialog } = useContext(DataContext);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    birth_place: "",
    license_number: "",
    license_first_issue_date: "",
    license_country: "",
    license_document_variation: "",
    address: "",
    phone: "",
    about_me: "",
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewLisenceUrl, setPreviewLisenceUrl] = useState("");
  const [previewLisenceUrl2, setPreviewLisenceUrl2] = useState("");
  function formatDate(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${month} ${day} at ${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  const navigate = useNavigate();
  useEffect(() => {
    getUserDetail(
      setUserData,
      setPreviewUrl,
      openDialog,
      navigate,
      setPreviewLisenceUrl,
      setPreviewLisenceUrl2
    );
  }, []);
  console.log("checkDate", formatDate(new Date("2024-08-10T19:04:00.000Z ")));
  function mapRatingToArray(rating) {
    const num = Number(rating);

    const resultArray = Array.from({ length: num }, (_, index) => index + 1);

    return resultArray;
  }
  return (
    <div class="col-sm-12 col-xs-12">
      <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
        <div class="cobalt-Card__Section">
          <div class="tab_panes_container">
            <article itemscope="" itemtype="http://schema.org/Person">
              <h1 class="header_title user_header_title" itemprop="name">
                profile
              </h1>
              <div class="row top20">
                <div class="col-sm-4 col-xs-12 no-outer-gutter-xs">
                  <div class="cobalt-Card cobalt-Card--responsive user_card c-mb-md">
                    <div class="cobalt-Card__Section">
                      <div class="user_card__infos">
                        <a href="/users/5581365">
                          <img
                            alt="Mdg"
                            width="100"
                            height="100"
                            class="cobalt-avatar user_card_picture"
                            src={previewUrl ? previewUrl : logo}
                          />
                        </a>
                        <div class="user_card__name_badge_container">
                          <div class="user_card__name js_open_preview cursor_pointer">
                            <a class="link-on-hover">{userData?.first_name}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                      <div class="c-text-body-md c-text-subdued color-grey">
                        Member since 2024
                      </div>
                    </div>

                    
                  </div>
                </div>

                <div class="col-sm-8 col-xs-12 no-outer-gutter-xs">
                  {/* {review?.detail?.map((val,index) => (
                  val?.reviews?.length>0&& */}
                  <div
                    class="js_cars_pagination_container"
                    data-infinite-pagination-added=""
                  >
                    <div class="js_cars_pagination_content">
                      <div
                        class=" c-mb-md js_car_reviews_infinite_pagination_container"
                        data-infinite-pagination-added=""
                      >
                        <div
                          style={{ borderTop: "none" }}
                          class="cobalt-Card__Section"
                        >
                          <h2 class="c-text-title-md c-mb-none">
                            {" "}
                            Information of {userData?.first_name}
                          </h2>
                          <button
                            style={{ width: "auto", marginLeft: "0" }}
                            class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                            onClick={() =>
                              navigate("/main-menu/profile/edit-profile")
                            }
                          >
                            Edit Profile
                          </button>
                         
                          {/* <div class="show_more_reviews">
                            <button
                              class="cob-Button cob-Button__ghost cob-Button--fullWidth js_infinite_pagination_trigger"
                              data-next-page-path="/cars/1391572/reviews?page=2&amp;paginate_per=5&amp;rel=next"
                            >
                              See more...
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="show_more_reviews">
                  <button
                    class="cob-Button cob-Button__default cob-Button--fullWidth c-mb-md js_cars_pagination_trigger"
                    data-next-page-path="/users/5581365/cars_with_reviews?page=2">
                    See more...
                  </button>
                </div> */}
                  </div>

                  {/* ))} */}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 col-xs-12 no-outer-gutter-xs">
                  <div class="cobalt-Card cobalt-Card--responsive user_card c-mb-md">
                    <div class="cobalt-Card__Section">
                    <h2 class="c-text-title-md c-mb-none">
                            {" "}
                            Verification of {userData?.first_name}
                          </h2>
                    </div>
                    <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                      <div class="c-text-body-md c-text-subdued color-grey">
                        Identity ✅
                      </div>
                      <div class="c-text-body-md c-text-subdued color-grey">
                        Phone Number ❌
                      </div>
                      <div class="c-text-body-md c-text-subdued color-grey">
                        Email Address ✅
                      </div>
                    </div>
                    <div class="cobalt-Card__Section relative">
                      <div class="row c-text-center">
                        <div class=" col-lg-5 col-lg-offset-1 col-xs-6">
                          <div class="c-text-title ">
                            <div class="user_card_icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M3.5461731,17.4538269 C3,18 3,18 3,18.5 L3,20.0008606 C3,21.0008606 3,21.0008606 4,21.0008606 L6,21.0008602 L6,19 L8,19 L8,16.9546871 L10,16.9546871 L10,15 L12,15 L12.496129,14.5 C14.5,15.4999998 16.9999999,14.500001 20,11.5000003 C21.5,10.0000001 22,8.00000011 19,5 C16,1.99999989 14,2.49999999 12.496129,3.99999997 C9.49420346,6.99419856 8.5,9.49999978 9.5,11.5 L3.5461731,17.4538269 L3.5461731,17.4538269 Z M15.6065774,8.39161392 C16.594318,9.37935454 17.7950389,9.7800776 18.2884626,9.28665392 C18.7818863,8.79323023 18.3811632,7.59250932 17.3934226,6.6047687 C16.405682,5.61702807 15.2049611,5.21630502 14.7115374,5.7097287 C14.2181137,6.20315238 14.6188368,7.40387329 15.6065774,8.39161392 L15.6065774,8.39161392 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            &nbsp;5
                          </div>
                          <div class="c-text-body-md c-text-subdued color-grey">
                            Cars Rental
                          </div>
                        </div>
                        <div class="col-lg-5 col-xs-6">
                          <div class="c-text-title">
                            <div class="user_card_icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M7.71163405,20.8562867 C6.84949963,21.3296772 5.84187365,20.5650648 6.00652667,19.5624075 L6.84488987,14.594771 L3.35617076,11.043006 C2.65868936,10.3329201 3.04356824,9.09575128 4.00746382,8.94946555 L8.80363311,8.21837144 L10.9461857,3.68418581 C11.3772529,2.77193806 12.6227471,2.77193806 13.0538143,3.68418581 L15.2307871,8.22679814 L19.9925362,8.94946555 C20.9564318,9.09575128 21.3413106,10.3329201 20.6438292,11.043006 L17.176653,14.5883666 L17.9934733,19.5624075 C18.1581264,20.5650648 17.1505004,21.3296772 16.2883659,20.8562867 L12.0263592,18.5160556 L7.71163405,20.8562867 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <span itemprop="ratingValue"> &nbsp;5</span>/
                            <span itemprop="bestRating">5</span>
                            <meta itemprop="worstRating" content="1" />
                          </div>
                          <div class="c-text-body-md c-text-subdued color-grey">
                            4 reviews
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
