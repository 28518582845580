import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reservation.css";
const CancelationType = () => {
  const { setSelectDataCaseThree, selectDataCaseThree, rentYourCar } =
    useContext(DataContext);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Car replace</div>
        <div className="">
          <div
            className="cobalt-CheckmarkField"
            data-form-checkmark-method="is_pro"
          >
            <div>
              <div className="discount-option">
                <input
                  type="radio"
                  required
                  name="car_replace"
                  checked={selectDataCaseThree.car_replace?.includes("1")}
                  value="1"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">Flexible</span> */}
                  <div className="discount-info">
                    <strong>Replacement</strong>
                    <p>
                    Possible replacement of the vehicle.
                    </p>
                  </div>
                </div>
              </div>

              <div className="discount-option">
                <input
                  type="radio"
                  required
                  name="car_replace"
                  checked={selectDataCaseThree.car_replace?.includes("2")}
                  value="2"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">10%</span> */}
                  <div className="discount-info">
                    <strong>Repair</strong>
                    <p>
                    Possible towing of the vehicle and upcoming repairs.
                    </p>
                  </div>
                </div>
              </div>

          
            </div>
          </div>
        </div>
       
        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default CancelationType;
