import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverSkillsNext = () => {
  const { rentYourCar, selectDriverCase, setSelectDriverCase } =
    useContext(DataContext);
  const [selectData, setSelectData] = useState({
    carBrand: "",
    carModel: "",
  });
  console.log("selectDatanew", selectDriverCase.education);

  useEffect(() => {
    if (rentYourCar?.make?.length > 0 && rentYourCar?.model?.length > 0) {
      setSelectData({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
      });
    }
  }, [rentYourCar]);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Driver Skills</div>
        <div class="c-mb-lg">
        

          <div class="cobalt-FormField" data-form-field-method="brand_id">
            <label class="cobalt-FormField__Label" for="car_energy">
            Education
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="education"
                value={selectDriverCase.education}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
              >
                {rentYourCar?.driver?.education_level?.map((val) => (
                  <option value={val.education_id}>{val.education_level}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="brand_id">
            <label class="cobalt-FormField__Label" for="car_energy">
            Trades & Professions
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="profession"
                value={selectDriverCase.profession}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
              >
                {rentYourCar?.driver?.trade_profession?.map((val) => (
                  <option value={val.trade_id}>{val.trade_profession}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Attract drivers to your listing and gain more bookings 💪
        </div>
      </div>
    </>
  );
};

export default DriverSkillsNext;
