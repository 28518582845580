import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const StepModal = () => {
  const { rentYourCar, selectDataCaseOne, setSelectDataCaseOne } =
    useContext(DataContext);
  const [selectData, setSelectData] = useState({
    carBrand: "",
    carModel: "",
  });
  console.log("selectDatanew", selectDataCaseOne);

  useEffect(() => {
    if (rentYourCar?.make?.length > 0 && rentYourCar?.model?.length > 0) {
      setSelectData({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
      });
    }
  }, [rentYourCar]);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Confirm the model of your car</div>
        <div class="c-mb-lg">
          <div class="cobalt-FormField" data-form-field-method="brand_id">
            <label class="cobalt-FormField__Label" for="car_brand_id">
              Make
            </label>
            <div class="cobalt-SelectField">
              <select
                class="js_car_brand cobalt-SelectField__Input"
                data-url-for-models="/car_models/models"
                required="required"
                name="carBrand"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.carBrand}
              >
                {rentYourCar?.make?.map((val) => (
                  <option value={val.id}>{val.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="model_id">
            <label class="cobalt-FormField__Label" for="car_model_id">
              Model
            </label>
            <div class="cobalt-SelectField">
              <select
                class="js_car_model cobalt-SelectField__Input"
                name="carModel"
                id="car_model_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.carModel}
              >
                {rentYourCar?.model?.map((val) => (
                  <option value={val.id}>{val.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="brand_id">
            {/* <label class="cobalt-FormField__Label" for="car_brand_id">
              Vehicle Type
            </label>
            <div class="cobalt-SelectField">
              <select
                class="js_car_brand cobalt-SelectField__Input"
                data-url-for-models="/car_models/models"
                required="required"
                name="vehicleType"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne?.vehicleType}

              >
                {rentYourCar?.vehcile_type
                  ?.map((val) => (
                  <option value={val.vehicle_type_id}>{val.vehicle_type}</option>
                ))}
              </select>
            </div> */}
            <label className="cobalt-FormField__Label"> Vehicle Type</label>
            <div className="cobalt-CheckablePillsGroup">
              {rentYourCar?.vehcile_type?.map((val) => (
                <div
                  className="cobalt-CheckablePillField"
                  key={val.vehicle_type_id}
                >
                  <label className="cobalt-CheckablePillField__LabelWrapper">
                    <input
                      id={`checkable-pill-${val.vehicle_type_id}`}
                      className="cobalt-CheckablePillField__Input"
                      type="radio"
                      checked={
                        selectDataCaseOne?.vehicleType == val.vehicle_type_id
                      }
                      value={val?.vehicle_type_id}
                      onChange={(e) =>
                        handleChange(
                          e,
                          setSelectDataCaseOne,
                          selectDataCaseOne,
                          "caseOne",
                          "radio"
                        )
                      }
                      name="vehicleType"
                    />
                    <span
                      style={{ height: "60px" }}
                      class="cobalt-CheckablePillField__Label"
                    >
                      <span
                        style={{ width: "50px", height: "50px" }}
                        class="cobalt-Icon cobalt-Icon--car"
                      >
                        <span class="cobalt-Icon cobalt-Icon--car">
                          <img
                            src={val?.image_url}
                            style={{ width: "50px", height: "50px" }}
                          />
                        </span>
                      </span>
                      {/* {val?.vehicle_type} */}
                    </span>{" "}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="energy">
            <label class="cobalt-FormField__Label" for="car_energy">
              Fuel
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="fuelType"
                value={selectDataCaseOne.fuelType}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
              >
                {rentYourCar?.fuel_type?.map((val) => (
                  <option value={val.fuel_type_id}>{val.fuel_type}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Completing your listing will only take a few minutes ⏱️
        </div>
      </div>
    </>
  );
};

export default StepModal;
