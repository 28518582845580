import React from "react";

const EditReduction = () => {
  return (
  
    <div
    className="cobalt-CheckmarkField"
    data-form-checkmark-method="is_pro"
  >
    <div>
      <div className="discount-option">
        <input
    
          type="checkbox"
          name="discount"
        //   checked={selectDataCaseThree.discount.includes("1")}
          value="1"
        //   onChange={(e) =>
        //     handleChange(
        //       e,
        //       setSelectDataCaseThree,
        //       selectDataCaseThree,
        //       "caseThree"
        //     )
        //   }
        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
              type="number"
              required
              //value={discount.discountOne}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
            //   onChange={(e) => {
            //     const value = e.target.value;

            //     // Allow only positive numbers
            //     if (value >= 0) {
            //       setDiscount({ ...discount, discountOne: value });
            //       // handleChange(
            //       //   e,
            //       //   setSelectDataCaseTwo,
            //       //   selectDataCaseTwo,
            //       //   "caseTwo"
            //       // );
            //     }
            //   }}
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Promotion Nouvelles annonces</strong>
            <p>Offer a % discount on your first 3 bookings</p>
          </div>
        </div>
      </div>

      <div className="discount-option">
        <input
          type="checkbox"
         
          name="discount"
        //   checked={selectDataCaseThree.discount.includes("2")}
          value="2"
        //   onChange={(e) =>
        //     handleChange(
        //       e,
        //       setSelectDataCaseThree,
        //       selectDataCaseThree,
        //       "caseThree"
        //     )
        //   }
        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
              required
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
            //   value={discount.discountTwo}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only positive numbers
                if (value >= 0) {
                //   setDiscount({ ...discount, discountTwo: value });

                  // handleChange(
                  //   e,
                  //   setSelectDataCaseTwo,
                  //   selectDataCaseTwo,
                  //   "caseTwo"
                  // );
                }
              }}
              type="number"
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Weekly reduction</strong>
            <p>For stays of 7 nights or more</p>
          </div>
        </div>
      </div>

      <div className="discount-option">
        <input
          type="checkbox"
          name="discount"
        //   required={
        //     selectDataCaseThree.discount?.length > 0 ? false : true
        //   }
        //   checked={selectDataCaseThree.discount.includes("3")}
          value="3"
        //   onChange={(e) =>
        //     handleChange(
        //       e,
        //       setSelectDataCaseThree,
        //       selectDataCaseThree,
        //       "caseThree"
        //     )
        //   }
        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
            //   value={discount.discountThree}
              required
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
            //   onChange={(e) => {
            //     const value = e.target.value;

            //     // Allow only positive numbers
            //     if (value >= 0) {
            //       setDiscount({ ...discount, discountThree: value });

            //       // handleChange(
            //       //   e,
            //       //   setSelectDataCaseTwo,
            //       //   selectDataCaseTwo,
            //       //   "caseTwo"
            //       // );
            //     }
            //   }}
              type="number"
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Monthly discount</strong>
            <p>For stays of 28 nights or more</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default EditReduction;
