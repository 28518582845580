import React, { useContext, useState, useRef } from "react";
import { useLoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import LocationSearch from "../../../ReusableComponent/LocationSearch";
import { DataContext } from "../../../ContextApi";
const containerStyle = {
    width: "100%",
    height: "200px", // Ensure the height is set
  };const EditLocation = () => {
    const {
        rentYourCar,
        setSearchVehicles,
    openDialog,
    setInputValue,
    setSelectDataCaseFiveLocation,
    selectDataCaseFiveLocation,
        
      } = useContext(DataContext);
      const [location, SetLocation] = useState({ lat: "", lng: "", location: "" });
console.log("locationns",location)
      const libraries = ["places"];
      const [latLng, setLatLng] = useState({});
      const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",
        libraries,
      });
      const mapRef = useRef(null);
    
      if (loadError) return <div>Error loading maps</div>;
      if (!isLoaded) return <div>Loading Maps</div>;
    
      const onMarkerDragEnd = async (event) => {
        const geocoder = new window.google.maps.Geocoder();
    
        const newLatLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
    
        setLatLng(newLatLng);
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setInputValue(results[0].formatted_address);
              setSelectDataCaseFiveLocation(results[0].formatted_address);
              console.log("currentLocation", results[0].formatted_address);
            } else {
              alert("No results found");
            }
          } else {
            alert("Geocoder failed due to: " + status);
          }
        });
      };
  return (
    
  <>
    <div style={{width:"100%"}} className="edit-form">
          <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
            Location
          </label>
          <div class="cobalt-TextField cobalt-TextField--withIcon">
            <LocationSearch
              value={true}
              setSearchVehicles={setSearchVehicles}
              searchPage={true}
              SetLocation={SetLocation}
              setLatLng={setLatLng}
            />

            <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
              </svg>
            </span>
          </div>
        </div>
        <div style={{ width: "100%" }} className="edit-form">
          {location?.location && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={15}
              center={latLng}
              onLoad={(map) => (mapRef.current = map)}
            >
              {latLng && (
                <MarkerF
                  position={latLng}
                  draggable={true} // Make the marker draggable
                  onDragEnd={onMarkerDragEnd} // Event when dragging ends
                />
              )}
            </GoogleMap>
          )}
        </div>
        <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div>
  </>


)
};

export default EditLocation;
