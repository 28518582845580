import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import {  TextareaAutosize } from "@mui/material";

const FeaturesOption = () => {
  const { rentYourCar, setSelectDataCaseThree, selectDataCaseThree } =
    useContext(DataContext);
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Any other features?</div>
        <div class="c-mb-lg">
          <div class="cobalt-FormField">
            <div class="cobalt-CheckablePillsGroup">
              {rentYourCar?.features?.map((val) => (
                <div
                  class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                  data-form-checkmark-method="equipments"
                >
                  <label
                    class="cobalt-CheckablePillField__LabelWrapper"
                  
                  >
                    <input
                      id={`checkable-pill-${val.feature_id}`}
                      className="cobalt-CheckablePillField__Input"
                      type="checkbox"
                      name="features"
                      required={selectDataCaseThree.features?.length>0?false :true}

                      checked={selectDataCaseThree.features.includes(val.feature_id)}
                      value={val.feature_id}
                      onChange={(e) =>
                        handleChange(
                          e,
                          setSelectDataCaseThree,
                          selectDataCaseThree,
                          "caseThree"
                        )
                      }
                    />
                    <span class="cobalt-CheckablePillField__Label">
                      <span class="cobalt-Icon cobalt-Icon--car">
                       
                        <span class="cobalt-Icon cobalt-Icon--car">
                        <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
                      </span>
                      </span>
                      {val?.feature_name}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="cobalt-TextField">
            <label className="modalLabel" htmlFor="first_name">
              Description
            </label>

            <TextareaAutosize
            style={{paddingLeft:"10px"}}
              name="description"
              required
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree"
                )
              }
             value={selectDataCaseThree.description}
            />
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Attract drivers to your listing and gain more bookings 💪
        </div>
      </div>
    </>
  );
};

export default FeaturesOption;
