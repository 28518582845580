import React, { useRef } from "react";
const testimonials = [
  {
    id: "ducas-sandero-2010",
    avatar:
      "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "Ducas SANDERO 2024",
    date: "le 8 septembre 2020",
    rating: "4",
    comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "peugeot-308-cc-2010",
    avatar:
      "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "Peugeot 308 CC 2010",
    date: "le 7 juillet 2014",
    rating: "5",
    comment: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem.",
  },
  {
    id: "fiat-500-2016",
    avatar:
      "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "4",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
  {
    id: "fiat-500-20167",
    avatar:
      "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "4",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
  {
    id: "fiat-500-201673",
    avatar:
      "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "5",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
];
const ReusableFeedback = ({feedback}) => {
  const testimonialsRef = useRef(null);
  function mapRatingToArray(rating) {
    const num = Number(rating);

    const resultArray = Array.from({ length: num }, (_, index) => index + 1);

    return resultArray;
  }
  const scrollPrevious = () => {
    if (testimonialsRef.current) {
      testimonialsRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollNext = () => {
    if (testimonialsRef.current) {
      testimonialsRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div class="home_internal_linking_section">
        {/* <section class="home_section home_section--flat">
    <div class="home_section__container">
      <div
        style={{
          textAlign: "center",
        }}
        class="c-text-title-lg "
      >
        Feedback{" "}
      </div>
    </div>
  </section> */}

        <section class="home_section news-snippets_section js_news-snippets">
          <div class="home_section__container ">
            <div
              style={{
                textAlign: "center",
              }}
              class="c-text-title-lg "
            >
              Feedback{" "}
            </div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end",
              }}
              class="seo-pages-1xhj18k eg8xb532"
            >
              <button
                onClick={scrollPrevious}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                aria-label="previous"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  fill="none"
                  viewBox="0 0 16 16"
                  class="seo-pages-1o6k71p"
                  role="img"
                  version="1.1"
                >
                  <path
                    fill="#121214"
                    fill-rule="evenodd"
                    d="M9.522 14a.75.75 0 0 1-.555-.245l-4.773-5.25a.754.754 0 0 1 0-1.01l4.773-5.25a.75.75 0 0 1 1.11 1.01L5.764 8l4.315 4.745A.75.75 0 0 1 9.522 14"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <button
                onClick={scrollNext}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                aria-label="next"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  fill="none"
                  viewBox="0 0 16 16"
                  class="seo-pages-1o6k71p"
                  role="img"
                  version="1.1"
                >
                  <path
                    fill="#121214"
                    fill-rule="evenodd"
                    d="M6.48 14a.75.75 0 0 0 .555-.245l4.773-5.25a.754.754 0 0 0 0-1.01l-4.773-5.25a.75.75 0 0 0-1.11 1.01L10.239 8l-4.314 4.745A.75.75 0 0 0 6.48 14"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div ref={testimonialsRef} className="testimonials  ">
              {feedback?.map((testimonial) => (
                <div key={testimonial?.booking_id} className="testimonial-card">
                  <img
                    src={testimonial?.cover_photo}
                    alt={testimonial?.first_name}
                    className="avatar"
                  />
                  <h3>{testimonial?.first_name}</h3>

                  <p className="date">{testimonial?.added_on}</p>
                  <div class="rating">
                    <div class="star_ratings">
                      <div class="cobalt-rating-icons">
                        {mapRatingToArray(testimonial?.rating).map(
                          (val, index) => (
                            <span
                              key={index}
                              class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                ></path>
                              </svg>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                    <div
                      class="c-mb-sm"
                      itemprop="reviewRating"
                      itemscope=""
                      itemtype="http://schema.org/Rating"
                    >
                      <meta content="5.0" itemprop="ratingValue" />
                    </div>{" "}
                    <p className="comment">{testimonial.remarks}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReusableFeedback;
