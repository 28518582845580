import React, { useContext, useRef, useState } from "react";
import LocationSearch from "../../../../ReusableComponent/LocationSearch";
import { DataContext } from "../../../../ContextApi";
import {
  useLoadScript,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";const containerStyle = {
  width: "100%",
  height: "200px", // Ensure the height is set
};
const StandardParking = () => {
  const { setSearchVehicles   , setInputValue,     selectDataCaseFiveLocation, setSelectDataCaseFiveLocation,
  } = useContext(DataContext);
  const libraries = ["places"];
  const [latLng, setLatLng] = useState({});

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",
    libraries,
  });
  const mapRef = useRef(null);

console.log("selectDataCaseFiveLocation",selectDataCaseFiveLocation)
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>; 

  const onMarkerDragEnd = async (event) => {
    const geocoder = new window.google.maps.Geocoder();

    const newLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setLatLng(newLatLng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
         
          setInputValue(results[0].formatted_address)
          setSelectDataCaseFiveLocation({...newLatLng,location: results[0].formatted_address})
         console.log("currentLocation",results[0].formatted_address)
        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
 

  };
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">
          Where will you meet drivers for check-in?
        </div>
        <div class="c-mb-lg">
          <div class="c-mb-sm">
            <div
              class="js_address_section"
              data-marker-icon="carPrecise"
              data-address-details="{}"
              data-car-registration-country="DE"
            >
              <div class="js_AddressAutocomplete-container">
                <div class="Address-autocomplete Address-autocomplete--powered-by-google">
                <div class="cobalt-FormField" data-form-field-method="address_line1">
            <label class="cobalt-FormField__Label" for="car_address_line1">
            Commune
            </label>
            <div class="cobalt-TextField">
              <input
                class="cobalt-TextField__Input"
                type="text"
                name="address"
                placeholder="Commune"
                // value={selectDataCaseFour?.address}

                // onChange={(e) =>
                //   handleChange(
                //     e,
                //     setSelectDataCaseFour,
                //     selectDataCaseFour,
                //     "caseFour"
                //   )
                // }
              />
            </div>
          </div>
                  <div class="cobalt-FormField">
                    <label class="cobalt-FormField__Label" for="car_address">
                      Meeting point
                    </label>
                    <div
                      class="cobalt-Autocomplete"
                      data-reach-combobox=""
                      data-state="idle"
                    >
                      <div class="cobalt-Autocomplete__input-wrapper">
                        <div class="cobalt-TextField cobalt-TextField--withIcon">
                          <LocationSearch
                            value={true}
                            setSearchVehicles={setSearchVehicles}
                            searchPage={true}
                            SetLocation={setSelectDataCaseFiveLocation}
                            setLatLng={setLatLng}
                          />
                          <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {selectDataCaseFiveLocation&&
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={15}
                center={latLng}
                onLoad={(map) => (mapRef.current = map)}
              >
                {latLng && (
                  <MarkerF position={latLng}
                  draggable={true} // Make the marker draggable
                  onDragEnd={onMarkerDragEnd} // Event when dragging ends
                   />
                )}
              </GoogleMap>
              }
              <div
                class="cobalt-Callout cobalt-Callout--error hidden_content top10 bottom10"
                data-show="submit"
                style={{ display: "none" }}
              >
                Please specify the parking address of your car
              </div>
              <div
                class="cobalt-Callout cobalt-Callout--error hidden_content top10 bottom10"
                data-show="suggestions_and_no_choice"
                style={{ display: "none" }}
              >
                Warning: you didn't select an address among the choices offered,
                please make sure that our suggestion is accurate.
              </div>
              <div
                class="js_car-map-loading c-w-full c-flex c-justify-center c-items-center c-bg-grey-lighter c-rounded"
                hidden=""
                style={{ display: "none" }}
                // style={{ height: "200px" }}
              >
                <div class="cobalt-Spinner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div
                class="hidden_content"
                data-show="choice,suggestions_and_no_choice"
                style={{ display: "none" }}
              >
                <div
                  class="js_car_map"
                  style={{ width: "100%", height: "200px" }}
                ></div>
                <div
                  class="c-mt-sm js_car_address_error_instructions hidden_content"
                  data-show="suggestions_and_no_choice"
                  style={{ display: "none" }}
                >
                  If this address is incorrect, please input your address again
                  or pin your car's location on a map:
                  <div class="top10">
                    <a
                      class="cob-Button cob-Button__ghost js_popup_trigger js_custom_address_popin_trigger"
                      href="#custom-address-popin"
                    >
                      Pin my car on a map
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="hidden_content"
                data-show="no_suggestions"
                style={{ display: "none" }}
              >
                <div class="cobalt-Callout cobalt-Callout--error top10">
                  We're sorry but we cannot find this address.<br></br>Please
                  retry to input an address or pin your car's location on a map:
                  <div class="top10">
                    <a
                      class="cob-Button cob-Button__ghost js_popup_trigger js_custom_address_popin_trigger"
                      href="#custom-address-popin"
                    >
                      Pin my car on a map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cobalt-Note cobalt-Note--fullWidth">
            This address will be used to allow drivers to rent your car
          </div>
        </div>
        <div class="car_wizard__footer_note">
          <p class="c-text-body-md c-text-subdued">
            You can change this information later
          </p>
        </div>
      </div>
    </>
  );
};

export default StandardParking;
