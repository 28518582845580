import React, { createContext, useState, useEffect } from "react";
import { loginApi, SignupApi } from "../api";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loggedIn = localStorage.getItem("token") && "true";
    console.log("loggedIn", loggedIn);
    setIsLoggedIn(loggedIn);

    setLoading(false);
  }, []);

  const login = (
    username,
    password,
    setLoader,
    setError,
    navigate,
    openDialog
  ) => {
    let payload = { email: username, password: password };

    loginApi(payload, setIsLoggedIn, setLoader, setError, navigate, openDialog);
  };
  const signUp = async (
    firstName,
    lastName,
    username,
    phone,
    password,
    capcha
  ) => {
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email: username,

      phone: phone,
      password: password,
      // capcha: capcha,
    };

    return await SignupApi(payload);
  };
  const logout = (navigate) => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("cover_photo");
    localStorage.removeItem("name");
    localStorage.removeItem('is_billing_address');
    sessionStorage.removeItem("lastRoute");
    sessionStorage.removeItem("profile_status");
    localStorage.removeItem("company_type");
      localStorage.removeItem("user_type")
localStorage.clear()
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, loading, login, logout, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
}
