// DataContext.js
import React, { createContext, useEffect, useState } from "react";
import { getFeedback, getrentYourCarData } from "../api";
import {  Dialog } from "@material-ui/core";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [filterValue, setFilterValue] = useState({
    vehicleType: [],
    driverOption: {},
    pick_up_date_time: null,
    drop_off_date_time: null,
    instantBooking: false,
    numberSeats: 0,
    newCarOnly: false,
    features: [],
    transmission: {},
    fuelType: [],
    carMake: [],
    acceptRate: false,
    price: 30,
    // lat:"",
    // lng:""
  });
  const [imageArray, setImageArray] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [rentYourCar, setRentYourCar] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [alert, setAlert] = useState("");
  const [imageURLs, setImageURLs] = useState([]); // Array to store image URLs

  const [selectDataCaseOne, setSelectDataCaseOne] = useState({
    carBrand: "",
    carModel: "",
    releaseYear: "",
    milage: "",
    country: "",
    vehicleType: "",
    transmissionType: "",
    fuel_type: "",
  });

  const [selectDataCaseTwo, setSelectDataCaseTwo] = useState({
    plate_number: "",
    country_id: "",
    state_id: "",
    registration_year: "",
    milage: "",
    max_km_offered_per_day: "",
    price_per_km_max_exceeded: "",
    price_outof_region: "",
    region_state_id: "",
    price: "",
    with_driver_price: "",
    with_driver_extra_price_out_of_region: "",
    price_airport_dropoff: "",
    price_airport_pickup: "",
    minimum_days: "",
    maximum_days: "",
  });
  const [selectDataCaseThree, setSelectDataCaseThree] = useState({
    fuel_type: "",
    transmission_id: "",
    car_replace: "",
    doors: "",
    seats: "",
    weeklyReduction: "",
    monthlyReduction: "",
    promotionDiscount: "",
    features: [],
    discount: [],
    cancelation: [],
    is_company: "1",
    description: "",
    usable_volume: "",
    payload: "",
    internal_length: "",
    internal_width: "",
    internal_height: "",
    issue_date: "",
    insurance_date: "",
    expiry_date: "",
    types_insurance: [],
  });
  const [checkboxValues, setCheckboxValues] = useState({
    pickup: 0,
    dropoff: 0,
    withDriver: 0,
    withoutDriver: 0,
  });
  const [selectDataCaseFour, setSelectDataCaseFour] = useState({
    address: "",
    address2: "",

    zip_code: "",
    city: "",
    country: "",
    driver_option_id: [],
    state: "",
    phone: "",
    country_code: "",
    booking_option_id:"",

    is_wellMaintained: "",
    is_upto_date_roadworthiness: "",
  });
  const [selectDriverCase, setSelectDriverCase] = useState({
    licenseCategory: "",
    licenseIssueCountry: "",
    licenseNumber: "",
    dateIssue: "",
    expiryDate: "",

    dateBirth: "",
    bloodType: "",
    languages: [],
    touristGuide: "1",
    education: "",
    profession: "",
    priceDay: "",
    weeklyReduction: "",
    monthlyReduction: "",
    promotionDiscount: "",
    priceWeekend: "",
    priceOutRegion: "",
    priceOutZone: "",
    cancelation: "1",
    promotion: [],
    minimumRental: "",
    maximumDuration: "",
    states: [],
    address: "",
    zipCode: "",
    city: "",
    country: "",
    state: "",
    description: "",
    identityPhoto: "",
    licenseFrontPhoto: "",
    licenseBackPhoto: "",
    westRegion: "",
    centerRegion: "",
    northRegion: "",
    southRegion: "",
    estRegion: "",
    southEst: "",
    southWest: "",
  });
  const [selectStateDriverCase, setSelectStateDriverCase] = useState({
    states: [],
    westRegion: "",
    centerRegion: "",
    northRegion: "",
    southRegion: "",
    estRegion: "",
    southEst: "",
    southWest: "",
  });
  const [selectStateWithoutDriverCase, setSelectStateWithoutDriverCase] =
    useState({
      states: [],
      westRegion: "",
      centerRegion: "",
      northRegion: "",
      southRegion: "",
      estRegion: "",
      southEst: "",
      southWest: "",
    });
  const [formData, setFormData] = useState({
    carService: "", // Initialize with empty string or default value
    region_id: "", // Initialize with empty string or default value
  });

  const [selectDataCaseFiveLocation, setSelectDataCaseFiveLocation] =
    useState({lat
      : 
     "",
      lng
      : 
     "",
      location
      : 
      ""});
  console.log("selectDataCaseFiveLocation", selectDataCaseFiveLocation);

  const [latLngRegion, setLatLngRegion] = useState({ lat: "", lng: "" });
  useEffect(() => {
    getrentYourCarData(setRentYourCar);
    getFeedback(setFeedback);
  }, []);
  useEffect(() => {
    const savedStateOne = getSavedState("caseOne");
    const savedStateTwo = getSavedState("caseTwo");
    const savedStateThree = getSavedState("caseThree");
    const savedStateFour = getSavedState("caseFour");
    const savedDriver = getSavedState("caseDriver");
    const savedWithDriver = getSavedState("caseWithDriver");
    const savedWithOutDriver = getSavedState("caseWithOutDriver");
    const savedRegionService=getSavedState("caseRegionService")
if(savedRegionService)
{
  setFormData({...savedRegionService})
}
else {
  setFormData({ carService: "", // Initialize with empty string or default value
    region_id: ""})
}
    if (savedStateOne) {
      setSelectDataCaseOne({ ...savedStateOne, price: "" });
    } else if (
      rentYourCar?.make?.length > 0 &&
      rentYourCar?.model?.length > 0 &&
      rentYourCar?.year?.length > 0
    ) {
      setSelectDataCaseOne({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
        releaseYear: rentYourCar.year[0].year_id,
        milage: rentYourCar.milage[0].milage_id,
        country: "",
        vehicleType: rentYourCar.vehcile_type[0].vehicle_type_id,
        transmissionType: rentYourCar.transmission[0].transmission_id,
        fuelType: rentYourCar?.fuel_type[0].fuel_type_id,
      });
    }

    if (savedStateTwo) {
      setSelectDataCaseTwo(savedStateTwo);
    } else if (
      rentYourCar?.country?.length > 0 &&
      rentYourCar?.state?.length > 0 &&
      rentYourCar?.milage?.length > 0 &&
      rentYourCar?.year?.length > 0
    ) {
      setSelectDataCaseTwo({
        plate_number: "",
        country_id: rentYourCar.country[0].id || "",
        state_id: rentYourCar.state[0].id || "",
        registration_year: rentYourCar.year[0].year_id || "",
        milage: rentYourCar.milage[0].milage_id || "",
        max_km_offered_per_day: "",
        price_per_km_max_exceeded: "",
        price_outof_region: "",
        region_state_id: rentYourCar.state[0].id || "",
        price: "",
        minimum_days: "",
        maximum_days: "",
      });
    }
    if (savedStateThree) {
      setSelectDataCaseThree(savedStateThree);
    } else if (
      rentYourCar?.fuel_type?.length > 0 &&
      rentYourCar?.transmission?.length > 0 &&
      rentYourCar?.doors?.length > 0 &&
      rentYourCar?.seats?.length > 0
    ) {
      setSelectDataCaseThree({
        ...selectDataCaseThree,
        transmission_id: rentYourCar.transmission[0].transmission_id || "",
        doors: rentYourCar.doors[0].door_id || "",
        seats: rentYourCar.seats[0].sear_id || "",
        fuel_type: rentYourCar?.fuel_type[0].fuel_type_id || "",
        features: [],
        weeklyReduction: "",
        monthlyReduction: "",
        promotionDiscount: "",
        discount: [],
        cancelation: [],

        is_company: "1",
        description: "",
      });
    }
    if (savedStateFour) {
      setSelectDataCaseFour(savedStateFour);
    } else if (
      rentYourCar?.country?.length > 0 &&
      rentYourCar?.state?.length > 0
    ) {
      setSelectDataCaseFour({
        address: "",
        address2: "",
        zip_code: "",
        city: "",
        driver_option_id: [],
        booking_option_id:
          "1",
        country: rentYourCar.country[0].id || "",
        state: rentYourCar.state[0].id || "",
        phone: "",

        country_code: "",
        is_wellMaintained: "",
        is_upto_date_roadworthiness: "",
      });
    }
    if (savedWithDriver) {
      setSelectStateDriverCase(savedWithDriver);
    } else {
      setSelectStateDriverCase({
        states: [],

        westRegion: "",
        centerRegion: "",
        northRegion: "",
        southRegion: "",
        estRegion: "",
        southEst: "",
        southWest: "",
      });
    }
    if (savedWithOutDriver) {
      setSelectStateWithoutDriverCase(savedWithOutDriver);
    } else {
      setSelectStateWithoutDriverCase({
        states: [],

        westRegion: "",
        centerRegion: "",
        northRegion: "",
        southRegion: "",
        estRegion: "",
        southEst: "",
        southWest: "",
      });
    }

    if (savedDriver) {
      setSelectDriverCase(savedDriver);
    } else if (rentYourCar?.all_country?.length > 0) {
      setSelectDriverCase({
        licenseCategory: rentYourCar?.driver?.license_types[0]?.license_type_id,
        licenseIssueCountry: rentYourCar.all_country[0].id || "",
        licenseNumber: "",
        weeklyReduction: "",
        monthlyReduction: "",
        promotionDiscount: "",
        dateIssue: "",
        expiryDate: "",
        dateBirth: "",
        bloodType: "",
        languages: [],
        touristGuide: "1",
        education: rentYourCar?.driver?.education_level[3]?.education_id,
        profession: rentYourCar?.driver?.trade_profession[0]?.trade_id,
        priceDay: "",
        priceWeekend: "",
        priceOutRegion: "",
        priceOutZone: "",
        cancelation: "",
        promotion: [],
        states: [],
        minimumRental: "",
        maximumDuration: "",
        address: "",
        zipCode: "",
        city: "",
        country: "",
        state: "",
        description: "",
        identityPhoto: "",
        licenseFrontPhoto: "",
        licenseBackPhoto: "",
        westRegion: "",
        centerRegion: "",
        northRegion: "",
        southRegion: "",
        estRegion: "",
        southEst: "",
        southWest: "",
      });
    }
  }, [rentYourCar]);

  const getSavedState = (key) => {
    const savedState = sessionStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : null;
  };

  const [searchVehicles, setSearchVehicles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [registerId, setRegisterId] = useState("");
  const [isFilterActive, setIsFilterActive] = useState({
    priceFilterActive: false,
    vehicleTypeFilter: false,
    pickupFilter: false,
  });
  console.log("selectDataCaseOne", selectDataCaseOne);
  const [state, setState] = useState({
    open: false,
    vertical: "center",
    horizontal: "center",
    message: "loves",
  });
  const { vertical, horizontal, open, message } = state;

  const handleClickSnackbar = (message, newState) => {
    setState({ ...newState, open: true, message: message });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [dialogState, setDialogState] = useState({
    open: false,
    message: "",
  });

  const openDialog = (message, prevState) => {
    setDialogState({ ...prevState, open: true, message });
  };

  const closeDialog = () => {
    setDialogState({ ...dialogState, open: false });
  };
  const handleCarServiceChange = (e) => {
    let updateData={...formData,
      carService: e.target.value,
    }
    setFormData(updateData
    );
    sessionStorage.setItem("caseRegionService",JSON.stringify(updateData))

  };

  // Handler for updating formData for region select
  const handleRegionChange = (e) => {
    let value=e.target.value;
    const filter=rentYourCar?.state?.find((val)=>val?.id==value)
    console.log("handleRegionChange",filter)
    let updateData={...formData,
      region_id: filter?.id,}
    setFormData(updateData);
    sessionStorage.setItem("caseRegionService",JSON.stringify(updateData))
  };
  return (
    <DataContext.Provider
      value={{
        selectDataCaseTwo,
        vertical,
        horizontal,
        open,
        handleRegionChange,handleCarServiceChange,
        handleClose,
        state,
        setState,
        handleClickSnackbar,
        message,
        setSelectDataCaseTwo,
        openDialog,
        imageURLs,
        setImageURLs,
        selectDriverCase,
        setSelectDriverCase,
        feedback,
        filterValue,
        selectDataCaseOne,
        selectDataCaseFour,
        setSelectDataCaseFour,
        registerId,
        setRegisterId,
        checkboxValues,
        setCheckboxValues,
        selectDataCaseThree,
        selectDataCaseFiveLocation,
        setSelectDataCaseFiveLocation,
        setSelectDataCaseThree,
        latLngRegion,
        setLatLngRegion,
        setSelectDataCaseOne,
        setFilterValue,
        searchVehicles,
        setSearchVehicles,
        loader,
        imageArray,
        setImageArray,
        inputValue,
        setInputValue,
        setLoader,
        isFilterActive,
        setIsFilterActive,
        rentYourCar,
        setRentYourCar,
        formData,
        setFormData,
        alert,
        selectStateWithoutDriverCase,
        setSelectStateWithoutDriverCase,
        selectStateDriverCase,
        setSelectStateDriverCase,
        setAlert,
      }}
    >
      {children}
      <Dialog
        open={dialogState?.open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
      >
        <div style={{ padding: "20px", fontFamily: "BrownProMarketing" }}>
          <div>{dialogState.message}</div>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <button
              style={{ width: "45px", height: "35px" }}
              className="button-color"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </div>
        {/* <DialogTitle id="alert-dialog-title">
          {dialogState.message}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </DataContext.Provider>
  );
};
