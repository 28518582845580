import React, { useState, useContext, useEffect, useRef } from "react";
import { DataContext } from "../../../ContextApi";
import {
  carImageUpload,
  carUnavailbilty,
  deleteImageUpload,
  getCarImages,
  getEditCarDetail,
  hardlyLogout,
  unavailablePeriod,
  UpdateCar,
} from "../../../api";

import Spinner from "../../../ReusableComponent/Spinner";

import { Modal } from "@material-ui/core";
import Unavailability from "../Unavailabilty";
import { addMoreImageArray } from "../../../ExportFiles";
import EditLeftbar from "./EditLeftbar";
import EditRightbar from "./EditRightbar";

export const EditCar = ({ openEditForm, handleCloseEditModal, id }) => {
  const {
    rentYourCar,
    setSearchVehicles,
    openDialog,
    setInputValue,
    setSelectDataCaseFiveLocation,
    selectDataCaseFiveLocation,
  } = useContext(DataContext);
  console.log("selectDataCaseFiveLocation", id);
  const [editList, setEditList] = useState([]);
  const [selectLeftBar, setSelectLeftBar] = useState("1");
  const [loader, setLoader] = useState(false);
  const [location, SetLocation] = useState({ lat: "", lng: "", location: "" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [unavailableRanges, setUnavailableRanges] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const libraries = ["places"];
  const [latLng, setLatLng] = useState({});
  const [loading, setLoading] = useState({});
  const [images, setImages] = useState(addMoreImageArray.map(() => null));
  const { imageArray, setImageArray } = useContext(DataContext);
  const billing = localStorage.getItem("is_billing_address");
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };

  const handleFileUpload = async (file, index) => {
    const formData = new FormData();
    formData.append("file_name", file);
    formData.append("vehicle_id", id);
    formData.append("image_id", index);

    setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
    try {
      const response = await carImageUpload(formData);
      console.log("File uploaded:", response.data);

      setImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = response.data.response; // Store the response data
        return newImages;
      });
      getCarImages(id, setImageArray, setLoading);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = (imageId, index) => {
    setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
    let payload = { vehicle_id: id, image_id: imageId };

    deleteImageUpload(payload, setLoading, getCarImages, setImageArray, id);
  };
  const handleFeatureChange = (e, featureId) => {
    if (e.target.checked) {
      setSelectedFeatures([...selectedFeatures, featureId]);
    } else {
      setSelectedFeatures(selectedFeatures.filter((id) => id !== featureId));
    }
  };

  // State to hold form data
  const [formData, setFormData] = useState({
    carBrand: "",
    carModel: "",
    releaseYear: "",
    mileage: "",
    location: { lat: "", lng: "", location: "" },
    price: "",
    plate_number: "",
    state_id: "",
    country_id: "",
    registration_year: "",
    vehicle_milage: "",
    fuel_type: "",
    transmission_id: "",
    doors: "",
    seats: "",
    is_company: "0",
    city: "",
    address: "",
    zip: "",
    billing_country: "",
    billing_state: "",
    billing_country: "",
    driver_option_id: [],
    booking_option_id: "",
    meeting_point: "",
    vehicle_type: "",
    vehicle_region: "",
    vehicle_location: "",
    max_km_offered_per_day: "",
    price_per_km_max_exceeded: "",
    price_outof_region: "",
  });
  const convertDate = (newDateString) => {
    const newDate = new Date(newDateString);
    return newDate.toISOString().replace("T", " ").split(".")[0];
  };

  const handleSaveUnavailable = async () => {
    if (startDate && endDate) {
      let payload = {
        vehicle_id: id,
        start_time: convertDate(startDate),
        end_time: convertDate(endDate),
      };
      try {
        const response = await carUnavailbilty(payload);
        setStartDate(null);
        setEndDate(null);
        openDialog(response?.data?.message);
        unavailablePeriod(id, setUnavailableRanges);
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  const highlightUnavailableDates = (date) => {
    return unavailableRanges?.some(
      (range) =>
        new Date(date) >= new Date(range.start) &&
        new Date(date) <= new Date(range.end)
    )
      ? "highlighted-date"
      : "";
  };
  const handleInputChangeDriverOption = (event) => {
    const { value, checked } = event.target;
    const featureId = parseInt(value); // Ensure featureId is an integer
    const updatedFeatures = checked
      ? [...formData.driver_option_id, featureId]
      : formData.driver_option_id.filter((id) => id !== featureId);

    setFormData({
      ...formData,
      driver_option_id: updatedFeatures, // Update driver options
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log("editCar", formData?.driver_option_id);
  const getDriverOptionIds = (driverOptions) => {
    // Ensure driverOptions is an array, and extract driver_option_id from each option
    return driverOptions.map((option) => option.driver_option_id);
  };

  const getEditDetail = async () => {
    try {
      const response = await getEditCarDetail(
        id,
        setLoader,
        setEditList,
        openDialog
      );
      setLoader(false);
    
      console.log("responser", response.data.response.result);
      // Set formData state with values from API
      setFormData(response.data.response.result);
      // setSelectDataCaseFiveLocation(meeting_point_address);
      // setInputValue(vehicle_location);
      // SetLocation({
      //   lat: latitude,
      //   lng: longitude,
      //   location: vehicle_location,
      // });
      // setSelectedFeatures(feature?.map((feature) => feature?.feature_id));
      // setUnavailableRanges(unavailable_dates);
    } catch (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      openDialog(response?.response?.data?.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(true);
    if (openEditForm) {
      getEditDetail();
      getCarImages(id, setImageArray);
    }
  }, [openEditForm]);
  const handleFormSubmit = () => {
    console.log(formData);
    let payload = {
      ...formData,
      features: selectedFeatures,
      ...location,
      selectDataCaseFiveLocation: location?.location,
      start_time: convertDate(startDate),
      end_time: convertDate(endDate),
    };

    UpdateCar(id, payload, handleCloseEditModal, openDialog);
  };
  return (
    <Modal
      open={openEditForm}
      onClose={handleCloseEditModal}
      // handleOperation={handleFormSubmit}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Set the background color
        timeout: 500,
      }}
    >
      <div className="modal-containers modal-con">
        <div className="modal-contents">
          <p
            class="close cross-icon"
            onClick={handleCloseEditModal}
            id="modal-description"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51672 15.8995C1.12447 15.9223 0.738678 15.7919 0.440711 15.5358C-0.146904 14.9447 -0.146904 13.99 0.440711 13.3989L13.3075 0.532C13.9187 -0.0398937 14.8777 -0.00810248 15.4496 0.603069C15.9668 1.15575 15.9969 2.00523 15.5202 2.59314L2.57756 15.5358C2.28347 15.7882 1.90384 15.9184 1.51672 15.8995Z"
                fill="white"
              />
              <path
                d="M14.3684 15.8996C13.9708 15.8979 13.5898 15.74 13.3075 15.4601L0.440589 2.59313C-0.103806 1.9574 -0.0297925 1.00067 0.605933 0.456224C1.17334 -0.0296788 2.01014 -0.0296788 2.57749 0.456224L15.5201 13.3231C16.1312 13.8952 16.1628 14.8542 15.5907 15.4653C15.568 15.4896 15.5444 15.5131 15.5201 15.5358C15.3633 15.6722 15.1802 15.7748 14.9821 15.8374C14.7839 15.9 14.575 15.9211 14.3684 15.8996Z"
                fill="white"
              />
            </svg>
          </p>
          <body id="search_show">
            <div class="js_site_content site_content">
              {loader ? (
                <Spinner />
              ) : (
                <div
                  class="row car_preview_row js_car_preview_row"
                  data-car-id="1475829"
                >
                  <div
                    style={{ background: "#fff" }}
                    class="col-md-12 col-sm-12 car_preview_content_xs car_preview_content_xs--to_the_top js_car_preview_content_xs"
                  >
                    {" "}
                    <div
                      style={{ overflow: "auto", height: "100vh" }}
                      class="col-md-6 col-sm-6 no-outer-gutter-xs"
                    >
                      <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                        <div class="cobalt-Card__Section">
                          <EditLeftbar
                            selectLeftBar={selectLeftBar}
                            setSelectLeftBar={setSelectLeftBar}
                            formData={formData}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ overflow: "auto", height: "100vh" }}
                      class="col-md-6 col-sm-6 no-outer-gutter-xs"
                    >
                      <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                        <div class="cobalt-Card__Section">
                          <EditRightbar formData={formData} id={id} selectLeftBar={selectLeftBar} handleCloseEditModal={handleCloseEditModal} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </body>
        </div>
      </div>
    </Modal>
  );
};
