import React, { useContext, useRef, useState } from "react";
import "./newbussiness.css";
import "./newUi.css"
import { DataContext } from "../ContextApi";
import { addCompany } from "../api";
import { useNavigate } from "react-router-dom";
import image1 from "../image/png/main_img1.png";
import image2 from "../image/png/main_img2.png";
import image3 from "../image/png/main_img3.png";
import image4 from "../image/png/main_img4.png";
import image5 from "../image/png/main_img5.png";
import image6 from "../image/png/main_img6.png";
import quotes from "../image/png/quotes.svg";
import ModalReusable from "../ReusableComponent/ModalReusable";
import ReusableFeedback from "./ReusableFeedback";
import ReusableFaq from "./ReusableFaq";

const inputFields = [
  { name: "company_name", label: "MY COMPANY", type: "text", required: true },
  { name: "admin_name", label: "MY NAME", type: "text", required: true },
  {
    name: "company_email",
    label: "E-MAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
  // {
  //   name: "admin_email",
  //   label: "ADMIN-MAIL",
  //   type: "email",
  //   required: true,
  //   placeholder: "your@email.com",
  // },
  { name: "company_phone", label: "PHONE", type: "text", required: true },
    { name: "password", label: "PASSWORD", type: "password", required: true },

  { name: "company_address", label: "ADDRESS", type: "text", required: false },
  { name: "company_city", label: "CITY", type: "text", required: false },
  { name: "company_country", label: "COUNTRY", type: "text", required: false },
  {
    name: "company_email",
    label: "E-MAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
];
const NewUiBusinesspace = () => {
  
  const [formValues, setFormValues] = useState({
    company_name: "",
    admin_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_country: "Senegal",
    // admin_email: "",
    company_type: "2", 
  });
  const scrollRef = useRef(null);
const [openModal,setOpenModal]=useState(false)
  const scrollToRef = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const navigate=useNavigate()
  const [open, setOpen] = useState(false);
  const { openDialog,feedback } = useContext(DataContext); 
  const handleChangeRadio=(names,value)=>{
    setFormValues({
      ...formValues,
      [names]: value,
    });
  }
  const handleClose=()=>{
    setOpenModal(false)
  }
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    // e.preventDefault();
    let payload={...formValues}
   addCompany(payload,openDialog,navigate,handleClose)

  };

  return (
    <body style={{ height: "auto" }} class="v1_body">
      <div class="js_site_content site_content">
        <div class="search-hero-container">
          <div class="container">
            <div class="c-flex-">
              <div id="offersandbonuses" class="v1__section-4">
                <div class="v1__container-3">
                  <div class="hostcom_offers_grid">
                    <div
                      id="w-node-_8df7cebf-65d6-c7ca-eba4-a08a71f8661c-e419be09"
                      class="hostcom_offers_grid_div1"
                    >
                      <div class="v1__content_wrap">
                        <div class="hostcom_offers_grid_content1">
                          <h2 class="v1_heading_32-10 margin--none">
                            <strong class="type-color--white">
                              Make your business trips easier
                            </strong>
                          </h2>
                          <div class="v1_paragraph_regular-2 margin--none">
                            <span class="type-color--white">
                              Access vehicles available throughout France 24/7{" "}
                            </span>
                            <br></br>
                          </div>
                          <a
                           onClick={scrollToRef}
                            data-w-id="8df7cebf-65d6-c7ca-eba4-a08a71f86626"
                            target="_blank"
                            class="v1_arrow_button_large_wrapper-2 is--white w-inline-block"
                          >
                            <div class="v1_arrow_button_in space-between">
                              <div>Create my Business Space</div>
                              <div class="v1_arrow_button_arrow w-embed">
                                <svg
                                  width="auto"
                                  height="auto"
                                  viewBox="0 0 32 31"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.1402 24.007L14.8516 22.7183L22.6421 14.9083L14.8516 7.11775L16.1402 5.80957L25.2389 14.9083L16.1402 24.007Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M23.9479 13.9912H7.37109V15.8266H23.9479V13.9912Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M15.9835 30.6271C12.9714 30.6271 10.0269 29.7339 7.52239 28.0605C5.0179 26.387 3.06589 24.0085 1.9132 21.2257C0.760505 18.4428 0.458908 15.3807 1.04655 12.4264C1.63418 9.47216 3.08466 6.75851 5.21455 4.62862C7.34445 2.49872 10.0581 1.04824 13.0124 0.460608C15.9666 -0.127029 19.0288 0.174567 21.8116 1.32726C24.5944 2.47995 26.973 4.43196 28.6464 6.93646C30.3199 9.44095 31.2131 12.3854 31.2131 15.3976C31.2079 19.4351 29.6017 23.3058 26.7467 26.1608C23.8917 29.0158 20.021 30.622 15.9835 30.6271ZM15.9835 2.00334C13.3344 2.00334 10.7447 2.78889 8.54206 4.26067C6.3394 5.73245 4.62262 7.82434 3.60885 10.2718C2.59507 12.7193 2.32982 15.4124 2.84664 18.0106C3.36346 20.6089 4.63913 22.9955 6.51235 24.8687C8.38557 26.7419 10.7722 28.0176 13.3704 28.5344C15.9686 29.0512 18.6618 28.786 21.1092 27.7722C23.5567 26.7584 25.6486 25.0417 27.1204 22.839C28.5922 20.6363 29.3777 18.0467 29.3777 15.3976C29.3726 11.8468 27.9597 8.4429 25.4489 5.93212C22.9382 3.42133 19.5343 2.0085 15.9835 2.00334Z"
                                    fill="CurrentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="w-node-_8df7cebf-65d6-c7ca-eba4-a08a71f8662b-e419be09"
                      class="hostcom_offers_grid_div2"
                    >
                      <div class="hostcom_offers_grid_div2_content">
                        <img
                          src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a22_Car%20Illustration.png"
                          loading="lazy"
                          width="231"
                          alt=""
                          class="hostcom_offers_grid_div2_image"
                        />
                        <div class="general_content_spacing _0-50">
                          <h2 class="v1_heading_26-2 margin--none">
                            <strong>Partners, Deals &amp; Discounts</strong>
                          </h2>
                          <div class="v1_paragraph_regular-2 margin--none">
                            As a Overlo host, you get access to exclusive
                            deals and discounts with our entire network of
                            partners.<br></br>
                          </div>
                          <a
                            class="v1_arrow_button_regular_wrapper-copy"
                          >
                            <div class="v1_arrow_button_in space-between">
                              <div>Explore Offers</div>
                              <div class="v1_arrow_button_arrow regular w-embed">
                                <svg
                                  width="auto"
                                  height="auto"
                                  viewBox="0 0 32 31"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.1402 24.007L14.8516 22.7183L22.6421 14.9083L14.8516 7.11775L16.1402 5.80957L25.2389 14.9083L16.1402 24.007Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M23.9479 13.9912H7.37109V15.8266H23.9479V13.9912Z"
                                    fill="CurrentColor"
                                  ></path>
                                  <path
                                    d="M15.9835 30.6271C12.9714 30.6271 10.0269 29.7339 7.52239 28.0605C5.0179 26.387 3.06589 24.0085 1.9132 21.2257C0.760505 18.4428 0.458908 15.3807 1.04655 12.4264C1.63418 9.47216 3.08466 6.75851 5.21455 4.62862C7.34445 2.49872 10.0581 1.04824 13.0124 0.460608C15.9666 -0.127029 19.0288 0.174567 21.8116 1.32726C24.5944 2.47995 26.973 4.43196 28.6464 6.93646C30.3199 9.44095 31.2131 12.3854 31.2131 15.3976C31.2079 19.4351 29.6017 23.3058 26.7467 26.1608C23.8917 29.0158 20.021 30.622 15.9835 30.6271ZM15.9835 2.00334C13.3344 2.00334 10.7447 2.78889 8.54206 4.26067C6.3394 5.73245 4.62262 7.82434 3.60885 10.2718C2.59507 12.7193 2.32982 15.4124 2.84664 18.0106C3.36346 20.6089 4.63913 22.9955 6.51235 24.8687C8.38557 26.7419 10.7722 28.0176 13.3704 28.5344C15.9686 29.0512 18.6618 28.786 21.1092 27.7722C23.5567 26.7584 25.6486 25.0417 27.1204 22.839C28.5922 20.6363 29.3777 18.0467 29.3777 15.3976C29.3726 11.8468 27.9597 8.4429 25.4489 5.93212C22.9382 3.42133 19.5343 2.0085 15.9835 2.00334Z"
                                    fill="CurrentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_8df7cebf-65d6-c7ca-eba4-a08a71f8663a-e419be09"
                      class="hostcom_offers_grid_div2"
                    >
                      <div class="hostcom_offers_grid_div2_content refer-a-friend">
                        <div class="general_content_spacing _0-50">
                          <h2 class="v1_heading_26-2 margin--none">
                            <strong>Refer a friend, earn money</strong>
                          </h2>
                          <div class="v1_paragraph_regular-2 margin--none">
                            Earn even more when you refer a friend to host on
                            Getaround.<br></br>
                            <a class="link-type--underline-4">
                              <strong>Share your referral link</strong>
                            </a>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="owner_homepage_usps">
            <div class="container">
              <ul class="owner_usps">
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">88,000 +</div>
                  <p class="c-text-body-md c-text-subdued">
                    Professional users
                  </p>
                </li>
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">200,000 +</div>
                  <p class="c-text-body-md c-text-subdued">Business trips</p>
                </li>
                <li class="owner_usps__item">
                  <div class="owner_usps__detail">1,400 +</div>
                  <p class="c-text-body-md c-text-subdued">
                    Registered companies
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section class="v1__section-2">
          <div class="v1__container-3">
            <div class="v1_margin-bottom _2">
              <div class="v1_heading_42 centered">
                Car sharing for professionals
              </div>
            </div>
            <div class="hostcom_menu_grid">
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
                  src={image1}
                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">Save time</div>
                <p>
                  A quick and digital reservation. An inventory carried out in 5
                  minutes.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
 src={image2}                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  100% guaranteed journeys
                </div>
                <p>
                  Each rental is fully insured by Axa, and covered by 24/7
                  roadside assistance.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
 src={image3}                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Customer service available 7 days a week
                </div>
                <p>
                  So that you are never left by the wayside, our team is here to
                  listen to you.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
 src={image4}                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Overlo chosen car
                </div>
                <p>
                  So that you are never left by the wayside, our team is here to
                  listen to you.
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
 src={image5}                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">100% free</div>
                <p>
                  Opening and managing your account without additional costs and
                  without commitment
                </p>
              </a>
              <a class="hostcom_menu_grid_block w-inline-block">
                <img
 src={image6}                  loading="lazy"
                  alt=""
                  class="hostcom_menu_grid_illustration"
                />
                <div class="hostcom_menu_grid_block_text">
                  Popular with businesses of all sizes
                </div>
                <p>
                  Whether you are a company, a self-employed person or an
                  association
                </p>
              </a>
            </div>
          </div>
        </section>

        <section class="v1__section-2">
          <div class="v1__container-3">
            <div class="v1_margin-bottom _2">
              <div class="v1_heading_42 centered">
                Features dedicated to businesses
              </div>
            </div>
            <div class="v1__content_wrap flex--h-double-column">
              <div class="v1__content">
                <img
                  src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a1b_ga-illustration-8.svg"
                  loading="lazy"
                  sizes="(max-width: 479px) 65vw, (max-width: 767px) 355px, (max-width: 991px) 38vw, 355px"
                  alt=""
                  class="dwu_steps-img"
                />
              </div>
              <div
                data-w-id="6bbbb8e9-7996-c4ed-fdae-dd093a3dc77f"
                class="v1__content"
              >
                <div class="v1__timeline_grid _4-itens">
                  <h3 id="sds_tmh_1" class="v1__timeline_heading _1 home">
                    Simplified accounting
                  </h3>
                  <div id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc783-f615a809">
                    <div
                      id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc784-f615a809"
                      class="v1__timeline_text _1 home"
                    >
                      Your payment receipts in the name of the company, and the
                      possibility of issuing your VAT invoices
                    </div>
                  </div>
                  <h3 class="v1__timeline_heading _2 home">
                    Centralized payment
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc790-f615a809"
                    class="v1__timeline_text _2 home"
                  >
                    A single payment method for travel for all your team
                    members.
                  </div>
                  <h3
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc792-f615a809"
                    class="v1__timeline_heading _3 home"
                  >
                    Managing your team
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc794-f615a809"
                    class="v1__timeline_text _3 home"
                  >
                    Add and remove your team members as you wish and without
                    limits
                  </div>
                  <h3
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc79b-f615a809"
                    class="v1__timeline_heading _4 home"
                  >
                    Or Connect
                  </h3>
                  <div
                    id="w-node-_6bbbb8e9-7996-c4ed-fdae-dd093a3dc79d-f615a809"
                    class="v1__timeline_text _4 home"
                  >
                    Once your Getaround rental starts, the car information will
                    appear in the Uber Driver app. Go online and start earning!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="v1__section-4">
          <div class="v1__container-3">
            <div class="v1_heading_42 centered">You are in good company</div>
            <div class="v1_margin-bottom _2 text-center">
              Find out what our customers say about us
            </div>
            <div class="v1_spacing-3"></div>
            <div
              data-delay="4000"
              data-animation="slide"
              class="v1_testimonials_slide__wrap w-slider"
            >
              <div
                class="v1_testimonials_slide__mask w-slider-mask"
                id="w-slider-mask-0"
              >
                <div
                  class="v1_testimonials_slide__card_wrap w-slide"
                  aria-label="1 of 6"
                  role="group"
                >
                  <div class="v1_testimonials_slide__card_out">
                    <div class="v1_testimonials_slide__card">
                      <img
                        src={quotes}
                        loading="lazy"
                        width="8.5"
                        alt=""
                        class="v1_testimonials_slide__card_quote"
                      />

                      <div class="tm_card_body_text">
                        Our filming is all over France. With my cameraman we
                        take the train and pick up the car at the station at any
                        time.<br></br>
                      </div>
                      <div class="tm_card_body_text">
                        ‍
                        <a target="_blank">
                          <span>
                            <strong class="bold-text-14">
                              Hélène M. - Journalist, Bordeaux
                            </strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v1_testimonials_slide__card_wrap w-slide"
                  aria-label="2 of 6"
                  role="group"
                  style={{
                    transition:
                      "all, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
                    transform: "translateX(0px)",
                    opacity: 1,
                  }}
                >
                  <div class="v1_testimonials_slide__card_out">
                    <div class="v1_testimonials_slide__card">
                      <img
                        src={quotes}
                        loading="lazy"
                        width="8.5"
                        alt=""
                        class="v1_testimonials_slide__card_quote"
                      />
                      <div class="tm_card_body_text">
                        All our employees were able to rent a car near their
                        home to come to our seminar in Île de France, it was
                        more practical for everyone.<br></br>
                      </div>
                      <div class="tm_card_body_text">
                        <a target="_blank">
                          <span>
                            <strong class="bold-text-13">
                              Laura R. - HR & Office Manager, Paris
                            </strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="helpcenter"
          class="v1__section-4"
          style={{ marginTop: "40px", marginBottom: "104px" }}
        >
          <div class="v1__container-3">
            <div
              data-w-id="8df7cebf-65d6-c7ca-eba4-a08a71f866e7"
              class="v1_ctaimgcard_wrap-3"
            >
              <div class="v1__content_wrap mobile-padding--none">
                <div class="v1_ctaimgcard_img_wrap-2">
                  <img
                    src="https://cdn.prod.website-files.com/581d2676fe18a07e43923cfc/65a584cc732f96d6a61f7a24_AS_488832115.png"
                    loading="lazy"
                    sizes="(max-width: 767px) 94vw, (max-width: 991px) 77vw, (max-width: 1919px) 73vw, 856.796875px"
                    alt=""
                    class="v1_ctaimgcard_img"
                  />
                </div>
              </div>
              <div
                class="v1_ctaimgcard_card_wrap-2 padding--top"
                style={{
                  willChange: "transform",
                  transform:
                    "translate3d(0px, -0.75244%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
              >
                <div class="general_content_spacing _0-50">
                  <h2 class="v1_heading_32-10 margin--none">
                    <strong>Achieve your CSR objectives</strong>
                  </h2>
                  <div class="v1_paragraph_regular-2 margin--none">
                    Car sharing plays an important role in decarbonizing
                    transportation, including your business footprint.<br></br>
                    <ul class="CSR_objectives">
                      <li>
                        <h4>1 connected car shared on Getaround</h4>
                        <p>replaces 4 individual vehicles</p>
                      </li>
                      <li>
                        <h4>-40% annual CO2 emissions</h4>
                        <p>switching from a vehicle to car sharing</p>
                      </li>
                      <li>
                        <h4>52 million km saved each year</h4>
                        <p>thanks to car sharing on Getaround in France</p>
                      </li>
                      <li>
                        <h4>Ademe figures, car sharing study, 2019</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section style={{display:"none"}} class="insurance_layer">
          <div class="container">
            <div class="wr">
              <div
                class="v1_heading_42 centered"
                style={{ color: "#fff", marginBottom: "50px",display:"none" }}
              >
                What is car sharing?
              </div>
              <div class="features features-trio features--left">
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/qpGqactlwkZTzkiY.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Individually shared vehicles
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing optimizes the use of cars by users: you share
                      the use of a vehicle but you use the vehicle alone when
                      you need it.<br></br>
                      Not to be confused with carpooling.
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/GVUIxrVGYoJQpdiR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Savings per month
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing reduces the costs associated with owning a
                      fleet of vehicles: insurance, maintenance, parking fees,
                      loan repayments. Focus your time solely on your area of
                      ​​activity.
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/gaAunYTFbSdLslMR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Protect the environment
                  </h3>
                  <div class="feature__description">
                    <p>
                      Car sharing means fewer vehicles on the road, therefore a
                      reduction in CO2 and greenhouse gas emissions. This allows
                      you to adopt a sustainable strategy to achieve your
                      objectives in this area. You can measure this impact using
                      the "ADEME Grid".
                    </p>
                  </div>
                </div>
                <div class="feature">
                  <div
                    class="icon small"
                    style={{
                      height: "24px",
                      width: "24px",
                      backgroundImage:
                        "url(/lib_kARCHgCXrDPmoAex/gaAunYTFbSdLslMR.png?w=48&amp;h=48)",
                    }}
                  ></div>
                  <h3 class="feature__title color-1 weight-text-m">
                    Preparing for the disappearance of the automobile in the
                    city
                  </h3>
                  <div class="feature__description">
                    <p>
                      Cities are setting an example by doing everything they can
                      to make the car disappear from the urban landscape. Owning
                      a fleet of vehicles will become increasingly difficult for
                      your business. Car sharing prepares you for a new era,
                      where the car will be a shared good (like the train, the
                      plane).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={scrollRef} class="owner_homepage_pros__layer">
            <div class="container owner_homepage_pros__container">
              <h2 class="owner_homepage_pros__headline">
                You are already a professional or want to become one with
                Overlo?
              </h2>
              <div class="owner_homepage_pros__cards_container">
                <div class="owner_homepage_pros__card_container">
                  <div class="cobalt-Card owner_homepage_pros__card">
                    <div class="">
                    <div style={{paddingLeft:"0px",paddingRight:"0px",borderRadius:"0px"}} class="blog-blockt-two col-lg-12 col-md-12 col-sm-12">

                    <div class=" inner-box wow fadeInUp animated" style={{visibility:"visible",animationName:"fadeInUp"}}>
                        <h3 class="title">Are You Looking <br></br>For a Car ?</h3>
                        <div class="text">We are committed to providing our customers with exceptional service.</div>
                        <a onClick={()=>{(setOpenModal(true));handleChangeRadio("company_type,2")}}  class="read-more">Get Started
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_601_692)">
                            <path d="M13.6109 0H5.05533C4.84037 0 4.66643 0.173943 4.66643 0.388901C4.66643 0.603859 4.84037 0.777802 5.05533 0.777802H12.6721L0.113697 13.3362C-0.0382246 13.4881 -0.0382246 13.7342 0.113697 13.8861C0.18964 13.962 0.289171 14 0.388666 14C0.488161 14 0.587656 13.962 0.663635 13.8861L13.222 1.3277V8.94447C13.222 9.15943 13.3959 9.33337 13.6109 9.33337C13.8259 9.33337 13.9998 9.15943 13.9998 8.94447V0.388901C13.9998 0.173943 13.8258 0 13.6109 0Z" fill="white"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0_601_692">
                            <rect width="14" height="14" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                        </a>
                        <div class="hover-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
                                <path d="M43.1686 14.8242C36.3829 14.8242 30.2324 18.8167 27.4699 25.0145L16.292 50.093C9.59105 50.5534 4.29688 56.1314 4.29688 62.9492V75.8398C4.29688 81.7725 9.10637 86.582 15.0391 86.582H17.9835C17.9994 85.7553 18.0204 84.9288 18.0469 84.1023C17.3476 82.6768 16.9533 81.0745 16.9533 79.3796L16.7578 71.7578C16.7578 66.1212 17.9046 60.9441 22.0885 60.1012C24.0773 59.7006 25.7424 58.3456 26.5573 56.4876L40.3605 25.0145C43.1228 18.8167 49.2733 14.8242 56.0592 14.8242H43.1686Z" fill="#CEE1F2"></path>
                                <path d="M94.9609 86.582C100.894 86.582 105.703 81.7725 105.703 75.8398V62.9492C105.703 55.8299 99.9318 50.0586 92.8125 50.0586L79.5736 24.2505C76.6474 18.4688 70.7184 14.8242 64.2383 14.8242H43.1686C36.3829 14.8242 30.2324 18.8167 27.4699 25.0145L16.292 50.093C9.59105 50.5534 4.29688 56.1314 4.29688 62.9492V75.8398C4.29688 81.7725 9.10637 86.582 15.0391 86.582" stroke="#405FF2" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M38.0269 95.1758C42.7731 95.1758 46.6207 91.3282 46.6207 86.582C46.6207 81.8358 42.7731 77.9883 38.0269 77.9883C33.2807 77.9883 29.4332 81.8358 29.4332 86.582C29.4332 91.3282 33.2807 95.1758 38.0269 95.1758Z" stroke="#405FF2" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M63.1641 86.582H49.8433" stroke="#405FF2" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M71.9727 95.1758C76.7189 95.1758 80.5664 91.3282 80.5664 86.582C80.5664 81.8358 76.7189 77.9883 71.9727 77.9883C67.2265 77.9883 63.3789 81.8358 63.3789 86.582C63.3789 91.3282 67.2265 95.1758 71.9727 95.1758Z" stroke="#405FF2" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M51.5608 66.8164L63.5304 55.2099C65.9362 52.8587 64.2729 48.7712 60.9101 48.7712H49.9475C46.5786 48.7712 44.9182 44.6705 47.3367 42.3234L59.7328 30.293" stroke="#FF5CF3" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    </div>
                 
                      {/* <div
                        class="owner_homepage_pros_split__image owner_homepage_pros_split__image--private"
                        style={{ backgroundImage: `url(${priva})` }}
                      ></div>
                      <div class="cobalt-Card__Section owner_homepage_pros_split__content">
                        <div class="c-text-title-md c-text-base c-mb-sm">
                          You're an individual...
                        </div>
                        <p class="c-text-body-md c-text-base">
                          and want to earn a living managing your own fleet of
                          cars on Overlo?
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="owner_homepage_pros__card_container">
                  <div class="cobalt-Card owner_homepage_pros__card">
                    <div class="">
                    <div style={{paddingLeft:"0px",paddingRight:"0px",borderRadius:"0px"}} class="blog-blockt-two col-lg-12 col-md-12 col-sm-12">
                    <div class="inner-box two wow fadeInUp animated" data-wow-delay="100ms" style={{visibility:"visible",animationName:"fadeInUp"}}>
                        <h3 class="title">Do You Want to <br></br>Sell a Car ?</h3>
                        <div class="text">We are committed to providing our customers with exceptional service.</div>
                        <a onClick={()=>{(setOpenModal(true));handleChangeRadio("company_type,1")}}class="read-more">Get Started
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_601_692)">
                            <path d="M13.6109 0H5.05533C4.84037 0 4.66643 0.173943 4.66643 0.388901C4.66643 0.603859 4.84037 0.777802 5.05533 0.777802H12.6721L0.113697 13.3362C-0.0382246 13.4881 -0.0382246 13.7342 0.113697 13.8861C0.18964 13.962 0.289171 14 0.388666 14C0.488161 14 0.587656 13.962 0.663635 13.8861L13.222 1.3277V8.94447C13.222 9.15943 13.3959 9.33337 13.6109 9.33337C13.8259 9.33337 13.9998 9.15943 13.9998 8.94447V0.388901C13.9998 0.173943 13.8258 0 13.6109 0Z" fill="white"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0_601_692">
                            <rect width="14" height="14" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                        </a>
                        <div class="hover-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
                                <path d="M17.1875 84.2276V25.7724C17.1875 13.9118 26.779 4.29688 38.6109 4.29688H25.5664C13.7008 4.29688 4.08203 13.9156 4.08203 25.7812V84.2188C4.08203 96.0841 13.7008 105.703 25.5664 105.703H38.6109C26.779 105.703 17.1875 96.0882 17.1875 84.2276Z" fill="#CEE1F2"></path>
                                <path d="M72.4023 104.506C70.1826 105.281 67.7967 105.703 65.3125 105.703H25.7812C13.9156 105.703 4.29688 96.0841 4.29688 84.2188V25.7812C4.29688 13.9156 13.9156 4.29688 25.7812 4.29688H65.3125C77.1779 4.29688 86.7969 13.9156 86.7969 25.7812V48.3398M54.7852 82.2852H71.1133M21.4844 82.0703L25.4341 86.1614C27.1343 87.8681 29.8912 87.8681 31.5915 86.1614L39.7461 77.7734" stroke="#405FF2" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M105.047 70.0629C100.32 68.2247 97.1951 67.9622 94.8535 67.9622C90.5029 67.9622 87.0117 71.489 87.0117 75.8398C87.0117 80.1906 90.9148 83.7175 96.6917 83.7175C101.681 83.7175 105.703 87.2444 105.703 91.5952C105.703 95.9458 101.961 99.4729 97.6106 99.4729C95.5763 99.4729 91.0458 98.8124 86.582 97.038M96.6797 67.9622V61.0156M96.6797 99.4727V105.703M57.793 57.793V59.5117M34.1602 57.793V59.5117" stroke="#FF5CF4" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M68.5352 36.7383H68.1835C68.1734 36.7146 68.1661 36.6902 68.1557 36.6667L64.3038 28.1203C64.3002 28.1123 64.2967 28.1046 64.2931 28.0966C62.5023 24.1867 58.9291 21.3217 54.734 20.4329C52.2427 19.9053 49.1996 19.5508 45.8829 19.5508C42.6308 19.5508 39.6816 19.8928 37.2649 20.402C33.0507 21.2902 29.4639 24.1577 27.6706 28.0728C27.6669 28.0807 27.6635 28.0887 27.6598 28.0966L23.7974 36.6665C23.7869 36.6899 23.7798 36.7144 23.7697 36.7381H23.418C21.0448 36.7381 19.1211 38.6618 19.1211 41.0349C19.1211 43.4081 21.0448 45.3318 23.418 45.3318V49.303C23.418 54.8137 27.8339 59.2969 33.2617 59.2969H58.6912C64.1193 59.2969 68.5352 54.8137 68.5352 49.3032V45.332C70.9083 45.332 72.832 43.4083 72.832 41.0352C72.832 38.662 70.9083 36.7383 68.5352 36.7383ZM35.4885 31.6415C36.1541 30.1969 37.4799 29.1393 39.0369 28.8112C40.6093 28.4799 42.9015 28.1445 45.8831 28.1445C48.9326 28.1445 51.3212 28.4945 52.953 28.8402C54.4951 29.167 55.811 30.2227 56.4755 31.6654L58.7617 36.7383H33.1914L35.4885 31.6415ZM35.0195 53.0664C32.1718 53.0664 29.8633 50.7579 29.8633 47.9102C29.8633 45.0624 32.1718 42.7539 35.0195 42.7539C37.8673 42.7539 40.1758 45.0624 40.1758 47.9102C40.1758 50.7579 37.8673 53.0664 35.0195 53.0664ZM56.9336 53.0664C54.0858 53.0664 51.7773 50.7579 51.7773 47.9102C51.7773 45.0624 54.0858 42.7539 56.9336 42.7539C59.7813 42.7539 62.0898 45.0624 62.0898 47.9102C62.0898 50.7579 59.7813 53.0664 56.9336 53.0664Z" fill="#FF5CF4"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                      {/* <div
                        class="owner_homepage_pros_split__image owner_homepage_pros_split__image--entrepreneur"
                        style={{ backgroundImage: `url(${entrepreneur})` }}
                      ></div>
                      <div class="cobalt-Card__Section owner_homepage_pros_split__content">
                        <div class="c-text-title-md c-text-base c-mb-sm">
                          You already run a rental agency, a body shop or a car
                          selling business...
                        </div>
                        <p class="c-text-body-md c-text-base">
                          and want to optimize your fleet occupancy rates?
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_center">
                <a
                  class="cob-Button cob-Button__default cob-Button--large js_tracking_element"
                  data-in-viewport-event="owner_landing_pros_offers_viewed"
                  href="/professionals"
                >
                  Discover our offers for pros
                </a>
              </div>
              <ReusableFeedback feedback={feedback?.result}/>
              <ReusableFaq/>
            </div>
          </section>
          <ModalReusable
        open={openModal}
        handleClose={handleClose}
        handleOperation={handleSubmit}
        buttonText={"Create my Business Space"}
        modalHeader={"Create Company"}
      >
        <div
          style={{
            height: "50vh",
            overflow: "auto",
            padding: "20px",
          }}
        >
         
         <div  id="form" class=" section--grid section--center dark" style={{
           
            border:"none"
          }}>
      <div class="wr titleAlign--">
        <div class="titleWrapper"></div>
        <div class="formWrapper"  style={{
                borderRadius: "3px",
                maxWidth: "600px",
                margin: "0 auto",
              }}>
        {/* <div className="fontfamily" data-form-checkmark-method="is_pro">
               
                <div style={{ display: "flex", gap: "20px" }}>
                  <label className="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      className="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="2"
                      checked={formValues.company_type === "2"}
                      name="company_type"
                      id="car_is_pro_true"
                      onChange={handleChange}
                    />
                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                    <span className="cobalt-CheckmarkField__Label">Rental</span>
                  </label>
                  <label className="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      className="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="1"
                      checked={formValues.company_type === "1"}
                      name="company_type"
                      id="car_is_pro_false"
                      onChange={()=>handleChange("company_type,1")}
                    />
                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                    <span className="cobalt-CheckmarkField__Label">
                      Renting
                    </span>
                  </label>
                </div>
              </div> */}
        <div
                // id="cf-a788qic6ecm0mdsy"
                // className="form"
                // style={{
                //   "--cf-bg": "#fafafa",
                //   "--cf-color": "#000",
                //   "--cf-placeholder": "#bbb",
                //   "--cf-borderColor": "rgba(126, 126, 126, 0.3)",
                // }}
                // data-siteid="8om2m5yd35wf"
                // data-formid="form_bHpHCmPSyakpdpqK"
                // onSubmit={handleSubmit}
              >
                {inputFields.map(
                  (field, index) =>
                    (open || index < 5) && (
                      <div className="formRow" key={field.name}>
                        <div
                          className="cf-field"
                          data-id={field.name.toUpperCase()}
                          data-label={field.label}
                          data-required={field.required}
                          data-field={field.type}
                        >
                          <label style={{ verticalAlign: "inherit" }}>
                            {field.label}{" "}
                            <span style={{ verticalAlign: "inherit",color:"rgb(255 180 0)" }}>*</span>
                          </label>
                          <input
                            type={field.type}
                            name={field.name}
                            value={formValues[field.name]}
                            placeholder={field.placeholder || ""}
                            onChange={handleChange}
                            required={field.required}
                            disabled={field.name === "company_country"}
                          />
                        </div>
                      </div>
                    )
                )}
                
                {/* <div className="ctaWrapper">
                  <button className="btn primary btn--large" type="submit">
                    Create my Business Space
                  </button>
                </div> */}
              </div>
              </div></div>
              </div>
        </div>
      </ModalReusable>
      
      </div>
    </body>
  );
};

export default NewUiBusinesspace;
