import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../ContextApi";

const EditInsurance = ({id,formData}) => {
    const {rentYourCar}=useContext(DataContext);
    const [formDataNew, setFormDataNew] = useState([]);
     useEffect(() => {
      setFormDataNew(formData?.other_insurance?.map((insurance) => insurance?.insurance_id))
       }, [formData])
       const handleFeatureChange = (e, featureId) => {
        if (e.target.checked) {
          setFormDataNew([...formDataNew, featureId]);
        } else {
          setFormDataNew(formDataNew.filter((id) => id !== featureId));
        }
      };
      console.log("insuranceing",formDataNew)
  return (

<>
<div
          className="cobalt-CheckmarkField"
          data-form-checkmark-method="is_pro"
        >
          <div>
            {rentYourCar?.insurance_types?.map((val) => (
              <div className="discount-option">
                <input
                  type="checkbox"
                  name="types_insurance"
                  checked={formDataNew?.includes(
                                            val.insurance_id
                                          )}
                                          onChange={(e) =>
                                            handleFeatureChange(
                                              e,
                                              val.insurance_id
                                            )
                                          }
                     value={val?.insurance_id}
                 
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">19%</span> */}
                  <div className="discount-info">
                    <strong>{val?.insurance_type}</strong>
                    <p>{val?.insurance_detail}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div>

</>  

);
};

export default EditInsurance;
