import React, { useState, useRef, useEffect, useContext } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useGeolocated } from "react-geolocated";
import location from "../image/svg/location.svg";
import { searchVehicle, searchVehicleWithoutResult } from "../api";
import { DataContext } from "../ContextApi";
import { useLocation } from "react-router-dom";

const libraries = ["places"];

function LocationSearch({
  setSearchVehicles,
  setInputValuesEnter,
  searchPage,
  SetLocation,
  setLatLng,
  type,
  addPickDrop,
}) {
  const {
    setLoader,
    filterValue,
    setFilterValue,
    inputValue,formData,
    setLatLngRegion,
    setInputValue,checkboxValues, setCheckboxValues,
    openDialog,
  } = useContext(DataContext);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",
    libraries,
  });
  const containerRef = useRef(null);
  const locationing = useLocation(); // This gives access to the current route

  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({
    description: "",
    latLng: null,
  });
  
console.log("formDatahmm",locationing?.pathname=="/search")
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues(prevState => ({
      ...prevState,
      [name]: checked ? 1 : 0,
    }));
  };
  useEffect(() => {
    if (!SetLocation) {
      const location = sessionStorage.getItem("location");
      if (location) {
        // Parse the JSON string back into an object
        const value = JSON.parse(location);
        setInputValue(
          typeof value?.description === "string"
            ? value.description
            : value.description.description
        );

        let obj = { ...value.latLng, ...filterValue,carService:formData?.carService||"" };
        searchVehicleWithoutResult(
          obj,
          setSearchVehicles,
          setLoader,
          openDialog
        );
        setLoader(true);

        // Update the state with the retrieved data
      }
      else if (formData?.region?.latitude&& locationing?.pathname === "/search") {
        
        let obj = { lat:formData?.region?.latitude||""
          ,lng:formData?.region?.longitude||"", ...filterValue,carService:formData?.carService||"" };
        searchVehicleWithoutResult(
          obj,
          setSearchVehicles,
          setLoader,
          openDialog
        );

      }
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [open, setOpen] = useState(false);
  const autocompleteService = useRef(null);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated();
  const handleSelectPlace = (place) => {
    const placeId = place.place_id;

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    placesService.getDetails({ placeId }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const latLng = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
        setLoader(true);
        setFilterValue({ ...filterValue, ...latLng });
        let obj = { ...latLng, ...filterValue,carService:formData?.carService||"" };
        if (!SetLocation) {
          searchVehicleWithoutResult(
            obj,
            setSearchVehicles,
            setLoader,
            openDialog
          );
        }

        const demy = {
          description: place.description,
          latLng: latLng,
        };
        if (setLatLng) {
          setLatLng(latLng);
        }
        setLatLngRegion(latLng)
        setSelectedPlace(demy);
        const location = JSON.stringify(demy);
        if (!SetLocation) {
          sessionStorage.setItem("location", location);
        }
        if (SetLocation) {
          SetLocation({ location: place.description, ...latLng });
        }
        setInputValue(place.description); // Set input value to selected place'sessionSto lat and long
        setOpen(false);
        setPlaces([]);
      }
    });
  };
  console.log("selectedPlace", selectedPlace);
  const handleFocus = () => {
    console.log("yesss");
    setOpen(true);
  };
  const handleUseCurrentLocation = () => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(
        coords.latitude,
        coords.longitude
      );
      const latLngNew = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
      setLatLngRegion(latLng)

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const currentLocation = {
              description: results[0].formatted_address,
            };
            const demy = {
              description: currentLocation,
              latLng: latLngNew,
            };

            setSelectedPlace(demy);
            if (setInputValuesEnter) {
              setInputValuesEnter(demy.description);
            }

            const location = JSON.stringify(demy);
            sessionStorage.setItem("location", location);
            setLoader(true);
            setFilterValue({ ...filterValue, ...latLngNew });
            let obj = {
              lat: latLngNew.lat,
              lng: latLngNew.lng,
              ...filterValue,carService:formData?.carService||""
            };

            searchVehicleWithoutResult(
              obj,
              setSearchVehicles,
              setLoader,
              openDialog
            );
            setInputValue(currentLocation.description);
            setOpen(false);
          } else {
            alert("No results found");
          }
        } else {
          alert("Geocoder failed due to: " + status);
        }
      });
    } else {
      alert("Geolocation is not enabled or available");
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log("handleInputChange", value);

    setInputValue(value);
    if (setInputValuesEnter) {
      setInputValuesEnter(value);
    }

    setOpen(true);

    if (value && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPlaces(predictions);
          }
        }
      );
    } else {
      setPlaces([]);
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  if (!autocompleteService.current && window.google) {
    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();
  }

  return (
    <div style={{ position: "relative" }} ref={containerRef}>
      <input
        style={{
          borderBottomLeftRadius: open && "0px",
          borderBottomRightRadius: open && "0px",
          borderBottom: open && "none",
          height: type && "72px",
          borderRadius: type && "36px",
          backgroundColor: type && "#f7f7f7",
        }}
        name="search"
        className={`cobalt-TextField__Input js_search_address js_focus_target js_address_autocomplete input-transition ${
          open ? "input-open" : ""
        }`}
        placeholder="Select service and Search Specific address, station..."
        type="text"
        disabled={formData?.region?.id}  

        required
        value={inputValue}
        onFocus={handleFocus}
        onChange={handleInputChange}
      />
      {/* {!open &&addPickDrop && (
        <div  class="drop_pick" data-bound="end">
          <div
            class="time_input js_time_input_value"
            data-bound="end"
            data-empty="Time"
            data-format="%-l:%M %p"
          >
            Drop &amp; Pick
          </div>
        </div>
      )} */}
      {open && (
        <div
          className={
            searchPage
              ? "custom-input-drop-down-search-page"
              : "custom-input-drop-down"
          }
        >
          {/* {addPickDrop && (
        <div style={{ padding: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <input
            type="checkbox"
            name="pickup"
            id="checkbox_pickup"
            checked={checkboxValues.pickup === 1}
            onChange={handleCheckboxChange}
            style={{ width: '18px', height: '18px', margin: '0px' }}
          />
          <label htmlFor="checkbox_pickup" style={{ padding: '0 5px', display: 'flex', alignItems: 'center', cursor: 'pointer', fontWeight: '700' }}>
            Airport Pick
          </label>

          <input
            type="checkbox"
            name="dropoff"
            id="checkbox_dropoff"
            checked={checkboxValues.dropoff === 1}
            onChange={handleCheckboxChange}
            style={{ width: '18px', height: '18px', margin: '0px' }}
          />
          <label htmlFor="checkbox_dropoff" style={{ padding: '0 5px', display: 'flex', alignItems: 'center', cursor: 'pointer', fontWeight: '700' }}>
            Airport Drop
          </label>

          <input
            type="checkbox"
            name="withDriver"
            id="checkbox_withDriver"
            checked={checkboxValues.withDriver === 1}
            onChange={handleCheckboxChange}
            style={{ width: '18px', height: '18px', margin: '0px' }}
          />
          <label htmlFor="checkbox_withDriver" style={{ padding: '0 5px', display: 'flex', alignItems: 'center', cursor: 'pointer', fontWeight: '700' }}>
            With Driver
          </label>

          <input
            type="checkbox"
            name="withoutDriver"
            id="checkbox_withoutDriver"
            checked={checkboxValues.withoutDriver === 1}
            onChange={handleCheckboxChange}
            style={{ width: '18px', height: '18px', margin: '0px' }}
          />
          <label htmlFor="checkbox_withoutDriver" style={{ padding: '0 5px', display: 'flex', alignItems: 'center', cursor: 'pointer', fontWeight: '700' }}>
            Without Driver
          </label>
        </div>
      )} */}
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            {!SetLocation && (
              <li
                onClick={handleUseCurrentLocation}
                style={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={location}
                  alt="location"
                  style={{
                    marginRight: "10px",
                    width: "20px",
                    marginLeft: "8px",
                  }}
                />
                <span style={{ color: " #FFC845" }}>Use Current Location</span>
              </li>
            )}
            {places.map((place, index) => (
              <li
                key={index}
                onClick={() => handleSelectPlace(place)}
                style={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={location}
                  alt="location"
                  style={{
                    marginRight: "10px",
                    width: "18px",
                    marginLeft: "8px",
                  }}
                />
                <span>{place.description}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* <CustomDropDownMenuOnly setOpen={setOpen} open={open} >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <li
            onClick={handleUseCurrentLocation}
            style={{ padding: "10px", display: "flex", gap: "10px",cursor:"pointer" }}
          >
            <img src={location} alt="location" /> <p> Use Current Location </p>
          </li>
          {places.map((place, index) => (
            <li
              style={{ padding: "10px", display: "flex", gap: "10px",cursor:"pointer" }}
              key={index}
              onClick={() => handleSelectPlace(place)}
            >
              <img src={location} alt="location" /> <p> {place.description} </p>
            </li>
          ))}
        </div>
      </CustomDropDownMenuOnly> */}
    </div>
  );
}

export default LocationSearch;
