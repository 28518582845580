import React, { useContext,useState,useEffect } from "react";
import { DataContext } from "../../../ContextApi";

const DoorSeats = ({id,formData}) => {
    const {rentYourCar}=useContext(DataContext)
    const [formDataNew, setFormDataNew] = useState({
     doors:"",seats:""
     
    });
    useEffect(() => {
   setFormDataNew({
    doors:formData?. vehicle_number_of_doors
    ,
    seats:formData?. vehicle_number_of_seats
    ,})
    }, [formData])
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormDataNew({
        ...formDataNew,
        [name]: value,
      });
    };
    console.log("formDataNew",formDataNew)

  return (

  <>

<div style={{ width: "100%" }} className="edit-form">
          <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
            Number of doors
          </label>
          <div className="cobalt-CheckablePillsGroup">
            {rentYourCar?.doors?.map((val) => (
              <div className="cobalt-CheckablePillField" key={val.door_id}>
                <label className="cobalt-CheckablePillField__LabelWrapper">
                  <input
                    id={`checkable-pill-${val.door_id}`}
                    className="cobalt-CheckablePillField__Input"
                    type="radio"
                    checked={formDataNew?.doors == val.door_id}
                    value={val.door_id}
                      onChange={handleInputChange}
                    name="doors"
                  />
                  <span className="cobalt-CheckablePillField__Label">
                    {val?.doors}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div style={{ width: "100%" }} className="edit-form">
          <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
            Number of seats{" "}
          </label>
          <div className="cobalt-CheckablePillsGroup">
            {rentYourCar?.seats?.map((val) => (
              <div className="cobalt-CheckablePillField" key={val.sear_id}>
                <label className="cobalt-CheckablePillField__LabelWrapper">
                  <input
                    id={`checkable-pill-${val.sear_id}`}
                    className="cobalt-CheckablePillField__Input"
                    type="radio"
                    checked={formDataNew?.seats == val.sear_id}
                    value={val.sear_id}
                      onChange={handleInputChange}
                    name="seats"
                  />
                  <span className="cobalt-CheckablePillField__Label">
                    {val?.seats}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div>

  </>

);
};

export default DoorSeats;
