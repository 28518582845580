import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reservation.css"
const Contact = () => {
  const { setSelectDataCaseFour, selectDataCaseFour, rentYourCar } =
    useContext(DataContext);
    const [selectedOption, setSelectedOption] = useState('1');

    const handleOptionChange = (option) => {
      let updatedData;


      updatedData = {
        ...selectDataCaseFour,
        booking_option_id: option,
      };
      setSelectDataCaseFour(updatedData);

      setSelectedOption(option);
    };
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Services Offered</div>
        <div className="">
          <div className="c-mb-lg">
            <div
              className="cobalt-CheckmarkField"
              data-form-checkmark-method="is_pro"
            >
              {rentYourCar?.driver_option?.map((val) => (
                <label className="cobalt-CheckmarkField__LabelWrapper">
                  <input
                    className="cobalt-CheckmarkField__Input"
                    type="checkbox"
                    required={selectDataCaseFour?.driver_option_id?.length>0?false :true}
                    
                    value={val?.driver_option_id}
                  
                    checked={selectDataCaseFour?.driver_option_id?.includes(val?.driver_option_id)}
                    name="driver_option_id"
                    id="car_is_pro_true"
                    onChange={(e) =>
                        handleChange(
                          e,
                          setSelectDataCaseFour,
                          selectDataCaseFour,
                          "caseFour"
                        )
                      }
                  />
                  <span className="cobalt-CheckmarkField__Checkmark"></span>
                  <span className="cobalt-CheckmarkField__Label">
                    {val?.driver_option}
                  </span>
                </label>
              ))}
            </div>
          </div>
          {/* <div className="cobalt-CheckmarkField" data-form-checkmark-method="is_pro">
          <label className="cobalt-CheckmarkField__LabelWrapper">
            <input
              className="cobalt-CheckmarkField__Input"
              type="radio"
              value="0"
              name="driver_option"
              id="car_is_pro_false"
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseFour,
                  selectDataCaseFour,
                  "caseFour",
                  "radio"
                )
              }
            />
            <span className="cobalt-CheckmarkField__Checkmark"></span>
            <span className="cobalt-CheckmarkField__Label">
              No, I’m a private owner
            </span>
          </label>
        </div> */}
        </div>
        <div class="c-text-title-md c-mb-lg">Confirmation mode</div>
        <div className="c-mb-2xl">
          <div className="c-mb-lg">
            <div
              className="cobalt-CheckmarkField"
              data-form-checkmark-method="is_pro"
            >
             {/* <label className="cobalt-CheckmarkField__LabelWrapper">
                  <input
                    className="cobalt-CheckmarkField__Input"
                    type="radio"
                    value={val?.booking_option_id}
                    checked={
                      selectDataCaseFour.booking_option_id ===
                      val.booking_option_id
                    }
                    name="booking_option_id"
                    id="car_is_pro_true"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDataCaseFour,
                        selectDataCaseFour,
                        "caseFour",
                        "radio"
                      )
                    }
                  />
                  <span className="cobalt-CheckmarkField__Checkmark"></span>
                  <span className="cobalt-CheckmarkField__Label">
                    {val?.booking_option}
                  </span>
                </label> */}
              {/* {rentYourCar?.booking_option?.map((val) => ( */}
                <div >
      <div
        className={`option-item ${selectDataCaseFour?.booking_option_id == '1' ? 'selected' : ''}`}
        onClick={() => handleOptionChange('1')}
      >
        <div className="option-content">
          <span className="option-title">Use instant booking</span>
          <p className="option-description">Travelers can book automatically.</p>
        </div>
        <span className="option-icon">⚡</span>
      </div>

      <div
        className={`option-item ${selectDataCaseFour?.booking_option_id == '2' ? 'selected' : ''}`}
        onClick={() => handleOptionChange('2')}
      >
        <div className="option-content">
          <span className="option-title">Accept or decline requests</span>
          <p className="option-description">Travelers must make a reservation request.</p>
        </div>
        <span className="option-icon">💬</span>
      </div>
    </div>
              {/* ))} */}
            </div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default Contact;
