import React, { useState } from "react";
import workHero from "../../image/png/works_hero.jpg";

import bg20 from "../../image/png/bg_20.png";
import workHero1 from "../../image/png/how-it-works-1.svg";
import workHero2 from "../../image/png/how-it-works-2.svg";
import workHero3 from "../../image/png/how-it-works-3.svg";
import workHero4 from "../../image/png/how-it-works-4.svg";
import workHero5 from "../../image/png/how-it-works-5.svg";
import number1Circle from "../../image/png/number_1_circle.svg";
import number2Circle from "../../image/png/number_2_circle.svg";
import number3Circle from "../../image/png/number_3_circle.svg";
import arrowRightCircle from "../../image/png/arrow_right_circle.svg";
import { useNavigate } from "react-router-dom";
import "./howwork.css"
import { useRef } from "react";
import { useEffect } from "react";
const HowWorks = () => {
  const navigate = useNavigate();
  const [owner,setOwner]=useState(true)
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  // Function to handle scroll and check if the element is sticky
  const handleScroll = () => {
    if (stickyRef.current) {
      const stickyTop = stickyRef.current.getBoundingClientRect().top;
      setIsSticky(stickyTop <= 70); // Adjust this threshold to your sticky position
    }
  };

  // Attach scroll listener on mount and detach on unmount
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <body
      style={{ backgroundColor: "#f1f1f1", height: "auto" }}
      class=""
      id="pages_landings_how_it_works"
    >
      <div class="js_site_content site_content">
        <div style={{ marginTop: "10px" }}>
          <div class="full_width_content default_background">
            <div class="container">
              <div class="how_it_works c-pb-2xl c-flex c-flex-col c-items-center">
                <div class="mkg-section-b-spacing c-w-full">
                  <div class="how_it_works__hero c-relative c-w-full c-pt-lg sm:c-pt-none">
                    <div
                      class="how_it_works__hero__image c-bg-neutral mkg-rounded-md"
                      style={{
                        backgroundImage: `url(${workHero})`,
                      }}
                    ></div>
                    <div class="how_it_works__hero__content c-bg-primary mkg-rounded-md c-p-md sm:c-p-2xl">
                      <div class="mkg-text-title-xl c-mb-md">
                        Have a car without having a car
                      </div>
                      <div class="c-font-brand">
                        Get access to a car nearby that’s ready to drive. Go for
                        an hour or a week - just return the car when you’re
                        done. Easy, right?
                      </div>
                    </div>
                  </div>
                </div>
<div ref={stickyRef}
      style={{
        position: "sticky",
        top: "70px",
        width: "100%",
      }}
>
                <div style={{background:"transparent",        justifyContent: isSticky ? "flex-end" : "center",
}} class="mkg-tabs c-mb-xl" data-tab-group="pickup_method">
                  <a
                    onClick={()=>setOwner(true)}
                   style={{background:!owner&&"#ccc"}}
                    className={owner?"mkg-tab c-flex c-flex-col c-items-center c-gap-xs mkg-tab--active":"mkg-tab c-flex c-flex-col c-items-center c-gap-xs"}                  >
                    <div class="icon">
                      <div
                        class="cobalt-bg-icon"
                        style={{
                          backgroundColor: "var(--c-icon-base)",
                          width: "32px",
                          height: "32px",
                          "--u":
                            "url(https://cobalt-assets.getaround.com/icons/connect_car.svg?compress=true)",
                          WebkitMaskImage: "var(--u)",
                          maskImage: "var(--u)",
                          WebkitMaskSize: "contain",
                          maskSize: "contain",
                        }}
                      ></div>
                    </div>
                    <div class="title">With Car Owner</div>
                  </a>
                  <a
                 
                                      onClick={()=>setOwner(false)}
                                      style={{background:owner&&"#ccc"}}

                    className={!owner?"mkg-tab c-flex c-flex-col c-items-center c-gap-xs mkg-tab--active":"mkg-tab c-flex c-flex-col c-items-center c-gap-xs"}
                  >
                    <div class="icon">
                      <div
                        class="cobalt-bg-icon"
                        // data-style="background-color:var(--c-icon-base);width: 32px;height:32px;--u:url(https://cobalt-assets.getaround.com/icons/meet_owner.svg?compress=true);-webkit-mask-image: var(--u);mask-image: var(--u);-webkit-mask-size: contain;mask-size: contain;"
                        style={{
                          backgroundColor: "var(--c-icon-base)",
                          width: "32px",
                          height: "32px",
                          "--u":
                            "url(https://cobalt-assets.getaround.com/icons/meet_owner.svg?compress=true)",
                          WebkitMaskImage: "var(--u)",
                          maskImage: "var(--u)",
                          WebkitMaskSize: "contain",
                          maskSize: "contain",
                        }}
                      ></div>
                    </div>
                    <div class="title">With Car Rental</div>
                  </a>
                </div>
                </div>
                {owner?
                <div class="mkg-rounded-md c-bg-primary c-p-md c-mb-2xl c-w-full sm:c-p-xl">
                  <div
                    class="c-flex c-flex-col c-gap-lg sm:c-gap-2xl"
                    data-tab-content="#connect"
                    data-tab-group="pickup_method"
                    hidden="true"
                  >
                    <div class="mkg-text-title-lg c-text-center">
                      Self-service cars you can access 24/7 with the app
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="character_with_giant_phone_pin"
                          width="250"
                          lazyload="false"
                          src={workHero1}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon"
                            style={{
                              backgroundSize: "contain",
                              width: "32px",
                              height: "32px",
                              backgroundImage: `url(${number1Circle})`,
                              WebkitMaskImage: "var(--u)",
                              maskImage: "var(--u)",
                              WebkitMaskSize: "contain",
                              maskSize: "contain",
                            }}
                          ></div>
                          Before the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Book a Overlo 
                            </div>
                            <p>
                              Choose your ideal car from the available ones
                              nearby.
                            </p>
                            <p>
                              You can filter by car type, price, and other
                              features you might need.
                            </p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Verify your profile
                            </div>
                            <p>Upload photos of your license and ID.</p>
                            <p>
                              You only need to do this the first time you use
                              Overlo .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="character_open_car"
                          width="250"
                          lazyload="false"
                          src={workHero2}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon"
                            style={{
                              backgroundSize: "contain",
                              width: "32px",
                              height: "32px",
                              backgroundImage: `url(${number2Circle})`,
                              WebkitMaskImage: "var(--u)",
                              maskImage: "var(--u)",
                              WebkitMaskSize: "contain",
                              maskSize: "contain",
                            }}
                          ></div>
                          During the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Access the car with your phone
                            </div>
                            <p>
                              Use the app to perform the checkin process, then
                              unlock the car in one tap. Ta-da!
                            </p>
                            <p>The keys will be inside.</p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Enjoy the drive!
                            </div>
                            <p>
                              10 mi/hour up to 200 mi, then 200 mi/day and
                              secondary drivers are included.
                            </p>
                            <p>Manage your entire trip with the app.</p>
                            <p>
                              Trips on Overlo are covered by insurance and
                              24/7 customer support.
                            </p>
                            <p>
                              You can choose the Plus or Premium protection plan
                              for peace of mind.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="character_lying_on_top_of_car"
                          width="250"
                          lazyload="false"
                          src={workHero3}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon"
                            style={{
                              backgroundSize: "contain",
                              width: "32px",
                              height: "32px",
                              backgroundImage: `url(${number3Circle})`,
                              WebkitMaskImage: "var(--u)",
                              maskImage: "var(--u)",
                              WebkitMaskSize: "contain",
                              maskSize: "contain",
                            }}
                          ></div>
                          After the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Return the car
                            </div>
                            <p>
                              Use the app to check out and end your trip. Leave
                              the keys inside the car and lock it with your
                              phone.
                            </p>
                            <p>
                              The rental price will be automatically adjusted if
                              you’ve driven further than expected or didn’t
                              refill the fuel tank
                              <a
                                target="_blank"
                                rel="noopener noreferer"
                                href="/help/articles/4d5c21bca922?from_search=ajustement"
                              >
                                (see details).
                              </a>
                            </p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Earn credits for your next trip
                            </div>
                            <p>
                              Share your referral code with your friends, and
                              they’ll get $20 credit.
                            </p>
                            <p>
                              When they complete their first trip, you’ll also
                              get $20 credit!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="c-flex c-flex-col c-gap-lg sm:c-gap-2xl"
                    data-tab-content="#without_connect"
                    data-tab-group="pickup_method"
                    // hidden="false"
                  >
                    <div class="mkg-text-title-lg c-text-center">
                      Meet the owner to pick up and return the car
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="character_with_giant_phone_pin"
                          width="250"
                          lazyload="false"
                          src={workHero1}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon lazy"
                            data-style="    background-size: contain;width: 32px;height:32px;background-image: url(assets/number_1_circle.svg);
;-webkit-mask-image: var(--u);mask-image: var(--u);-webkit-mask-size: contain;mask-size: contain;"
                          ></div>
                          Before the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Find a Overlo
                            </div>
                            <p>
                              Filter by car type, price, and other features you
                              might need.
                            </p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Book your Overlo
                            </div>
                            <p>
                              Send rental requests to the owners and confirm
                              your trip when an owner accepts your request.
                            </p>
                            <p>
                              For Instant Booking cars, your trip is
                              automatically confirmed.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="characters_exchanging_keys"
                          width="250"
                          lazyload="false"
                          src={workHero5}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon lazy"
                            data-style=" background-size: contain;width: 32px;height:32px;background-image: url(assets/number_2_circle.svg);
;-webkit-mask-image: var(--u);mask-image: var(--u);-webkit-mask-size: contain;mask-size: contain;"
                          ></div>
                          During the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Pick up the car
                            </div>
                            <p>Check in with the owner to receive the keys.</p>
                            <p>
                              The owner will verify your license, ID, and the
                              credit card used.
                            </p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Enjoy the drive!
                            </div>
                            <p>
                              10 mi/hour up to 200 mi, then 200 mi/day and
                              secondary drivers are included.
                            </p>
                            <p>Manage your entire trip with the app.</p>
                            <p>
                              Trips on Overlo are covered by insurance and
                              24/7 customer support.
                            </p>
                            <p>
                              You can choose the Plus or Premium protection plan
                              for peace of mind.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="how_it_works__explanation-section">
                      <div class="how_it_works__explanation-section__illu">
                        <img
                          class="cobalt-illustration-image "
                          role="presentation"
                          alt="character_lying_on_top_of_car"
                          width="250"
                          lazyload="false"
                          src={workHero3}
                        />
                      </div>
                      <div class="how_it_works__explanation-section__body">
                        <h2 class="c-mb-md c-text-title-lg c-text-accent c-flex c-items-center c-gap-xs c-justify-center sm:c-justify-start">
                          <div
                            class="cobalt-bg-icon lazy"
                            data-style="    background-size: contain;width: 32px;height:32px;background-image: url(assets/number_3_circle.svg);
;-webkit-mask-image: var(--u);mask-image: var(--u);-webkit-mask-size: contain;mask-size: contain;"
                          ></div>
                          After the trip
                        </h2>
                        <div class="c-text-body-lg c-flex c-flex-col c-gap-md">
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Return the car
                            </div>
                            <p>
                              Adjust the rental price with the owner if you’ve
                              driven further than expected or didn’t refill the
                              fuel tank
                              <a
                                target="_blank"
                                rel="noopener noreferer"
                                href="/help/articles/4d5c21bca922?from_search=ajustement"
                              >
                                (see details).
                              </a>
                            </p>
                          </div>
                          <div>
                            <div class="c-text-title-md c-mb-xs">
                              Earn credits for your next trip
                            </div>
                            <p>
                              Share your referral code with your friends, and
                              they’ll get $20 credit.
                            </p>
                            <p>
                              When they complete their first trip, you’ll also
                              get $20 credit!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
:
          <div class="mkg-rounded-md c-bg-primary c-p-md c-mb-2xl c-w-full sm:c-p-xl">
            <div class="c-flex c-flex-col c-gap-lg sm:c-gap-2xl" data-tab-content="#connect"
              data-tab-group="pickup_method">
              <div class="mkg-text-title-lg c-text-center" style={{fontWeight:"700"}}
              >
                Fonctionnement
              </div>
              <div class="how_it_works__explanation-section">
                <div class="how_it_works__explanation-section__body">               
                  <div class="seo-pages-56kd2n e12j2ev22">                  
                    <div class="seo-pages-1xhj18k e12j2ev24">
                      <div class="seo-pages-79elbk e12j2ev25">
                        <div class="seo-pages-1c365lr e12j2ev26"></div>
                        <div class="seo-pages-sx39a4 e12j2ev27">1</div>
                      </div>
                      <div class="seo-pages-17y9yfn e12j2ev28">
                        <h3 class="seo-pages-1aueyff-StyledText">Inscrivez votre voiture gratuitement</h3>
                        <p class="seo-pages-76lyvq-StyledText">Vous pouvez partager pratiquement n’importe quel véhicule, qu’il s’agisse
                          d’un camion ou d’une voiture sport. L’inscription ne prend qu’une dizaine de minutes et ne vous coûte rien,
                          aucuns frais d’inscription ou de mensualités!</p>
                      </div>
                    </div>
                    <div class="seo-pages-1xhj18k e12j2ev24">
                      <div class="seo-pages-79elbk e12j2ev25">
                        <div class="seo-pages-1c365lr e12j2ev26"></div>
                        <div class="seo-pages-sx39a4 e12j2ev27">2</div>
                      </div>
                      <div class="seo-pages-17y9yfn e12j2ev28">
                        <h3 class="seo-pages-1aueyff-StyledText">Fixez votre prix et vos règles</h3>
                        <p class="seo-pages-76lyvq-StyledText">Établissez vos propres règles et modifiez votre calendrier en fonction de
                          vos disponibilités. Fixez votre propre prix par jour ou laissez Turo l’ajuster automatiquement afin de maximiser
                          vos revenus.</p>
                      </div>
                    </div>
                    <div class="seo-pages-1xhj18k e12j2ev24">
                      <div class="seo-pages-79elbk e12j2ev25">
                        <div class="seo-pages-1c365lr e12j2ev26"></div>
                        <div class="seo-pages-sx39a4 e12j2ev27">3</div>
                      </div>
                      <div class="seo-pages-17y9yfn e12j2ev28">
                        <h3 class="seo-pages-1aueyff-StyledText">Accueillez les invités</h3>
                        <p class="seo-pages-76lyvq-StyledText">Lorsqu’un invité réserve votre voiture, confirmez avec lui les détails
                          relatifs à la prise en charge du véhicule.</p>
                        <p class="seo-pages-76lyvq-StyledText"></p>
                        <p class="seo-pages-76lyvq-StyledText">Pour vous simplifier la tâche et gagner du temps, n’oubliez pas d’utiliser
                          l’app Turo.</p>
                      </div>
                    </div>
                    <div class="seo-pages-1xhj18k e12j2ev24">
                      <div class="seo-pages-79elbk e12j2ev25">
                        <div class="seo-pages-sx39a4 e12j2ev27">4</div>
                      </div>
                      <div class="seo-pages-17y9yfn e12j2ev28">
                        <h3 class="seo-pages-1aueyff-StyledText">Gagnez de l’argent sans lever le petit doigt, ou presque</h3>
                        <p class="seo-pages-76lyvq-StyledText">Recevez vos revenus par dépôt direct dans les trois jours suivant la fin
                          des voyages. Vous obtenez 80% du prix du voyage, à moins d’avoir refusé la protection de Turo pour offrir votre
                          propre assurance de location commerciale. Les frais accessoires, par exemple pour le carburant ou le kilométrage
                          excédentaire, vous seront également remboursés.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="how_it_works__explanation-section__illu">
                  <img class="cobalt-illustration-image " role="presentation" alt="character_with_giant_phone_pin"
                    width="250" lazyload="false"
                    src={bg20}/>
                </div>
                
              </div>
              
            <div class="mkg-text-title-lg c-text-center" style={{fontWeight:"700"}}>
              Louez en toute assurance
            </div>
                <div class="how_it_works__explanation-section">                  
                  <div class="how_it_works__explanation-section__illu">
                    <img class="cobalt-illustration-image " role="presentation" alt="character_with_giant_phone_pin"
                      width="250" lazyload="false"
                      src={bg20}/>
                  </div>
                  <div class="how_it_works__explanation-section__body">               
                    <div class="seo-pages-56kd2n e12j2ev22">                  
                      <div class="seo-pages-1xhj18k e12j2ev24">                       
                        <div class="seo-pages-17y9yfn e12j2ev28">
                          <h3 class="seo-pages-1aueyff-StyledText">Inscrivez votre voiture gratuitement</h3>
                          <p class="seo-pages-76lyvq-StyledText">Vous pouvez partager pratiquement n’importe quel véhicule, qu’il s’agisse
                            d’un camion ou d’une voiture sport. L’inscription ne prend qu’une dizaine de minutes et ne vous coûte rien,
                            aucuns frais d’inscription ou de mensualités!</p>
                        </div>
                      </div>
                      <div class="seo-pages-1xhj18k e12j2ev24">                       
                        <div class="seo-pages-17y9yfn e12j2ev28">
                          <h3 class="seo-pages-1aueyff-StyledText">Fixez votre prix et vos règles</h3>
                          <p class="seo-pages-76lyvq-StyledText">Établissez vos propres règles et modifiez votre calendrier en fonction de
                            vos disponibilités. Fixez votre propre prix par jour ou laissez Turo l’ajuster automatiquement afin de maximiser
                            vos revenus.</p>
                        </div>
                      </div>   
                    </div>
                  </div>
                 
                  
                </div>
              
            </div>

          </div>
                }
                <div class="help-sections c-w-full c-flex c-flex-col c-gap-xl mkg-section-b-spacing md:c-flex-row">
                  <div style={{background:"rgb(20, 62, 143)"}} class="help-section mkg-rounded-md mkg-bg-owner c-flex-1 c-flex c-flex-col c-overflow-hidden sm:c-flex-row">
                    <div class="c-p-md c-flex-1 sm:c-p-lg">
                      <div class="help-section__title c-text-title-lg c-text-inversed c-mb-md sm:c-mb-xl">
                        Want to rent out your car?
                      </div>
                      <a
                        class="cob-Button cob-Button__default cob-Button__default--primary mkg-button c-w-full sm:c-w-auto"
                        onClick={() => navigate("/rent-your-car")}
                      >
                        See how it works
                        <div class="c-w-md c-ml-xs">
                          <div
                            class="cobalt-bg-icon"
                            style={{
                              backgroundColor: "var(--c-icon-inversed)",
                              "--u": `url(${arrowRightCircle})`,
                              WebkitMaskImage: "var(--u)",
                              maskImage: "var(--u)",
                              WebkitMaskSize: "contain",
                              maskSize: "contain",
                            }}
                          ></div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="help-section__illustration c-h-full"
                      style={{
                        backgroundSize: "cover",
                        backgroundImage: `url(${workHero1})`,
                      }}
                    ></div>
                  </div>
                  <div class="help-section mkg-rounded-md c-bg-primary c-flex-1 c-flex c-flex-col sm:c-flex-row">
                    <div class="c-p-md c-pb-none c-flex-1 sm:c-p-lg">
                      <div class="help-section__title c-text-title-lg c-mb-md sm:c-mb-xl">
                        Still have questions?
                      </div>
                      <a
                        class="cob-Button cob-Button__default mkg-button mkg-button--outlined c-w-full sm:c-w-auto"
                        href="/help"
                      >
                        Check out our Help center
                        <div class="c-w-md c-ml-xs">
                          <div
                            class="cobalt-bg-icon"
                            style={{
                              backgroundColor: "var(--c-icon-accent)",
                              WebkitMaskImage: "var(--u)",
                              maskImage: "var(--u)",
                              WebkitMaskSize: "contain",
                              maskSize: "contain",
                            }}
                          ></div>
                        </div>
                      </a>
                    </div>
                    <div class="help-section__illustration c-flex c-justify-center c-items-center sm:c-pr-md">
                      <img
                        class="cobalt-illustration-image "
                        role="presentation"
                        alt="hello_headset"
                        width="180"
                        lazyload="false"
                        src={workHero4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default HowWorks;
