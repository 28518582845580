import React, { useContext, useEffect, useState } from "react";
import heroImage from "../../image/png/usp-hero.jpg";
// import "../../seo_landings.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";
import LocationSearch from "../../ReusableComponent/LocationSearch";
import DatePicker from "react-datepicker";
import { handleDate } from "../../ReusableComponent/OverAllFilter";
import { setHours, setMinutes, startOfToday } from "date-fns";
import pickup from "../../image/png/pickup.svg";
import drop from "../../image/png/drop.svg";
import appleAppImage from "../../image/png/apple_app.png";
import googleAppImage from "../../image/png/google_app.png";
import ReusableFeedback from "../ReusableFeedback";
import ReusableFaq from "../ReusableFaq";
const TypeCars = () => {
  const { id } = useParams();
  const { filterValue, setFilterValue, openDialog,feedback,setFormData,formData,rentYourCar,handleCarServiceChange,handleRegionChange } = useContext(DataContext);

  const [inputValueEnter, setInputValuesEnter] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();
  
  useEffect(() => {
    const storedPickDateTime = sessionStorage.getItem("pick_up_date_time");
    const storedDropDateTime = sessionStorage.getItem("drop_off_date_time");

    if (storedPickDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        pick_up_date_time: storedPickDateTime,
      }));
    }
    if (storedDropDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        drop_off_date_time: storedDropDateTime,
      }));
    }
    const location = sessionStorage.getItem("location");
    if (location) {
      // Parse the JSON string back into an object
      const value = JSON.parse(location);
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        lat: value.latLng.lat,
        lng: value.latLng.lng,
      }));
    }
  }, []);
 
  return (
    <div>
      <body id="seo_landings_show">
        <div class="js_site_content site_content">
          <header class="js_seo_block">
            <div class="c-bg-white">
              <div class="container">
                <div class="c-mt-sm c--mb-xs">
                  <div class="seo-breadcrumbs c-mb-md">
                    <a href="/">Overlo</a> › <a href="/">Rent a car</a> ›{" "}
                    <span class="current">Peer-to-peer Pickup rental</span>
                  </div>
                </div>
              </div>
              <div class="bleed-container md:bleed-container--bleedRight">
                <div class="bleed-wrapper bleed-container-gap-md">
                  <div class="bleed-left">
                    <div class="static-column">
                      <div class="search-form">
                        <div class="search_hero__content__wrapper">
                          <h1 class="c-text-title-xl js_seo_landing_baseline_wrapper">
                            {id === "pickup"
                              ? "Rent a pickup from a local"
                              : id === "commercial"
                              ? "Rent a commercial van from a local"
                              : id === "family"
                              ? "Rent a family car from a local"
                              : "Rent a SUV from a local"}
                          </h1>
                          <div class="c-text-subdued c-mb-md">
                            Unlock 24-hour self-service cars with the app and go
                          </div>
                          <div
                            class="js_search_form"
                            // id="new_order"
                          >
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                navigate("/search");
                                setFilterValue({
                                  ...filterValue,
                                  vehicleType: [
                                    {
                                      vehicle_type_id:
                                        id === "pickup"
                                          ? 11
                                          : id === "commercial"
                                          ? 1
                                          : id === "suv"
                                          ? 10
                                          : id === "family"
                                          ? 3
                                          : "",
                                      vehicle_type:
                                        id === "pickup"
                                          ? "Pickup"
                                          : id === "commercial"
                                          ? "Commercial"
                                          : id === "suv"
                                          ? "SUV"
                                          : id === "family"
                                          ? "Family"
                                          : "",
                                    },
                                  ],
                                });
                              }}
                            >
                              <div class="search_form">
                                <div class="search_form__params">
                                  <div class="search_form__address">
                                    <div class="js_AddressAutocomplete-container">
                                      <div class="Address-autocomplete">
                                        <div class="cobalt-TextField cobalt-TextField--withIcon">
                                        <div
                              style={{ marginBottom: "0px",justifyContent:"normal" }}
                              class="owner_homepage_hero_estimation_form__container js_car_model_estimation_form__container"
                            >
                              <select
                                style={{
                                  height: "72px ",
                                  borderRadius: "36px 0px 0px 36px ",
                                  border: "2px solid #bdbdbd ",
                                  borderRight: "none",
                                  boxShadow: "none ",
                                  paddingLeft: "18px ",
                                  paddingRight: "46px ",
                                  backgroundColor: "#f7f7f7 ",
                                }}
                                name="carBrand"
                                id="car_model_estimation_car_brand_id"
                                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                data-url-for-models="/car_models/models"
                                onChange={handleCarServiceChange}
                                value={formData.carService} // Bind value to state
                              >
                                <option value="">Select Service </option>
                                {rentYourCar?.services?.map((val) => (
                                  <option value={val?.service_id}>
                                    {val?.service_name}
                                  </option>
                                ))}
                              </select>
                              <select
                                name="carBrand"
                                id="car_model_estimation_car_brand_id"
                                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                data-url-for-models="/car_models/models"
                                style={{
                                  height: "72px",
                                  borderRadius: "0px 36px 36px 0px",
                                  border: "2px solid #bdbdbd",
                                  boxShadow: "none",
                                  paddingLeft: "18px",
                                  paddingRight: "46px",
                                  backgroundColor: "#f7f7f7",
                                }}
                                onChange={handleRegionChange}
                                value={formData.region_id || ''} // Bind to the region's id
                                >
                                <option value="">Select Region</option>

                                {rentYourCar?.state?.map((val) => (
                                  <option value={val?.id}>{val?.name}</option>
                                ))}
                              </select>
                            </div>
                                          {/* <LocationSearch
                                            setInputValuesEnter={
                                              setInputValuesEnter
                                            }
                                            type={true}
                                            addPickDrop={true}
                                          /> */}
                                          {/* <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                            <div class="cobalt-bg-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M12.825 21.325C13.076 21.073 19 15.092 19 10c0-3.86-3.14-7-7-7s-7 3.14-7 7c0 5.091 5.922 11.073 6.175 11.325a1.165 1.165 0 0 0 1.65 0M15.5 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0"
                                                  clip-rule="evenodd"
                                                />
                                              </svg>
                                            </div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class={
                                      inputValueEnter
                                        ? "search_form__dates after-location-added js_search_form__dates"
                                        : "search_form__dates hidden_order_form_fields js_search_form__dates"
                                    }
                                  >
                                    <div class="datetime_range_wrapper js_datetime_range">
                                      <div class="datetime_range_input js_datetime_range_input is_layout_home">
                                        <div
                                          style={{ position: "relative" }}
                                          className="custom-datepicker-wrapper"
                                        >
                                          <DatePicker
                                            selected={
                                              filterValue?.pick_up_date_time
                                                ? new Date(
                                                    filterValue.pick_up_date_time
                                                  )
                                                : null
                                            }
                                            onChange={(e) => {
                                              handleDate(
                                                e,
                                                setFilterValue,
                                                filterValue,
                                                "pick_up_date_time",
                                                openDialog
                                              );
                                              // handleSeach() // Uncomment if needed
                                            }}
                                            minDate={startOfToday()}
                                            showTimeSelect
                                            placeholderText="Pickup"
                                            excludeTimes={[
                                              setHours(
                                                setMinutes(new Date(), 0),
                                                17
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                18
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                19
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                17
                                              ),
                                            ]}
                                            dateFormat="MMM d, yyyy h:mm aa"
                                            popperPlacement="bottom-start" // Ensures calendar opens at the right place
                                            portalId="root-portal" // Ensure you have a div with this id in your HTML
                                          />
                                          <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                            <div class="cobalt-bg-icon">
                                              <img
                                                src={pickup}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  marginLeft: "10px",
                                                }}
                                                alt="pickup"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{ position: "relative" }}
                                          className="custom-datepicker-wrapper"
                                        >
                                          <DatePicker
                                            selected={
                                              filterValue?.drop_off_date_time
                                                ? new Date(
                                                    filterValue.drop_off_date_time
                                                  )
                                                : null
                                            }
                                            minDate={
                                              filterValue?.pick_up_date_time
                                                ? new Date(
                                                    filterValue.pick_up_date_time
                                                  )
                                                : new Date()
                                            }
                                            onChange={(newValue) => {
                                              handleDate(
                                                newValue,
                                                setFilterValue,
                                                filterValue,
                                                "drop_off_date_time",

                                                openDialog
                                              );
                                            }}
                                            popperPlacement="bottom-start" // Ensures calendar opens at the right place
                                            portalId="root-portal" // Ensure you have a div with this id in your HTML
                                            disabled={
                                              !filterValue?.pick_up_date_time
                                            }
                                            showTimeSelect
                                            placeholderText="Return"
                                            excludeTimes={[
                                              setHours(
                                                setMinutes(new Date(), 0),
                                                17
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                18
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                19
                                              ),
                                              setHours(
                                                setMinutes(new Date(), 30),
                                                17
                                              ),
                                            ]}
                                            dateFormat="MMM d, yyyy h:mm aa"
                                          />
                                          <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                            <div class="cobalt-bg-icon">
                                              <img
                                                src={drop}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  marginLeft: "10px",
                                                }}
                                                alt="drop"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="search_form__action">
                                  <button
                                    type="submit"
                                    class="cob-Button cob-Button--large cob-Button__default cob-Button__default--primary js_search_form_submit"
                                  >
                                    Find a car{" "}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="search_hero__insurance">
                            <div>
                              <h1>{t("car_rental_by_localswith")}</h1>
                            </div>
                          </div>

                          <div class="third-parties-rating">
                            <div class="c-flex c-gap-xs c-mb-sm c-justify-center md:c-justify-start">
                              <a
                                target="_blank"
                                rel="noopener"
                                class="mobile-app-section__app_badge js_mobile-app-section__app_badge"
                                href=""
                              >
                                <img
                                  width="120"
                                  height="40"
                                  alt="download"
                                  src={appleAppImage}
                                />
                              </a>
                              <a
                                target="_blank"
                                rel="noopener"
                                class="mobile-app-section__app_badge js_mobile-app-section__app_badge"
                                href=""
                              >
                                <img
                                  width="137"
                                  height="40"
                                  alt="download"
                                  src={googleAppImage}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bleed-right city-hero layer header_layer seo_landing_container"></div>
                </div>
              </div>
            </div>
          </header>
          <div class="layer js_seo_block">
            <div class="main-usp js_how_it_works_section c-bg-amber">
              <div class="bleed-container bleed-container--bleedRight c-pb-xl sm:c-py-xl">
                <div class="bleed-wrapper bleed-container-gap-md">
                  <div class="bleed-left">
                    <div class="static-column">
                      <div class="c-flex c-flex-col c-gap-md c-items-start c-pt-lg sm:c-py-xl">
                        <div class="c-text-title-lg">
                          Discover the new way<br></br>
                          <em>to rent a car</em>
                        </div>
                        <p class="c-text-body-md">
                          Choose from thousands of cars available from private
                          and professional owners near you.
                        </p>
                        <div class="c-flex c-gap-sm">
                          <div class="c-w-md">
                            <img
                              alt="icon"
                              width="24"
                              height="24"
                              src="https://getaround-assets.gumlet.io/images/shared/getaround_usp/ic-happyface-line-base.png?compress=true&amp;h=24&amp;mode=crop&amp;w=24"
                            />
                          </div>
                          <div class="c-flex c-flex-col c-gap-xs">
                            <div class="c-text-title-sm">
                              Prices by the hour or day
                            </div>
                            <p>
                              Trip liability insurance is included. You can even
                              add another driver at no extra cost.
                            </p>
                          </div>
                        </div>
                        <div class="c-flex c-gap-sm">
                          <div class="c-w-md">
                            <img
                              alt="icon"
                              width="24"
                              height="24"
                              class=""
                              src="https://getaround-assets.gumlet.io/images/shared/getaround_usp/ic-checkmark-line-base.png?compress=true&amp;h=24&amp;mode=crop&amp;w=24"
                            />
                          </div>
                          <div class="c-flex c-flex-col c-gap-xs">
                            <div class="c-text-title-sm">No waiting around</div>
                            <p>
                              Book a car near you instantly, even at the last
                              minute. No lines. No paperwork.
                            </p>
                          </div>
                        </div>
                        <div class="c-flex c-gap-sm">
                          <div class="c-w-md">
                            <div class="cobalt-bg-icon">
                              <svg
                                width="96"
                                height="96"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M23.017 6.448A11 11 0 0 0 13 0a1 1 0 1 0 0 2 9 9 0 0 1 8.196 5.276 1 1 0 1 0 1.82-.828Zm-3.643 1.656A7 7 0 0 0 13 4a1 1 0 1 0 0 2 5 5 0 0 1 4.554 2.932 1 1 0 0 0 1.82-.828Zm-2.635 3.919A1.5 1.5 0 1 1 17 15h-.005c.005.35.005.686.005 1v4.997A1 1 0 0 1 15.99 22h-1.98A1 1 0 0 1 13 20.997V20H6v.997A1 1 0 0 1 4.99 22H3.01A1 1 0 0 1 2 20.997V16c0-.314 0-.65.005-1H2a1.5 1.5 0 1 1 .261-2.977C2.674 9.878 3.742 8 6.5 8h6c2.758 0 3.826 1.878 4.239 4.023Zm-1.834 1.284C14.662 10.927 14.018 10 12.5 10h-6c-1.517 0-2.16.928-2.406 3.307a15.38 15.38 0 0 0-.056.693h10.924c-.014-.236-.032-.46-.056-.693ZM6.25 17a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM14 18.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="c-flex c-flex-col c-gap-xs">
                            <div class="c-text-title-sm">
                              Unlock the car with the app
                            </div>
                            <p>
                              Our secure Getaround Connect technology allows you
                              to do the walkaround inspection of the car with
                              the app. The car opens. The keys are inside. Off
                              you go!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bleed-right c-bg-neutralAlt main-usp__hero"
                    style={{
                      backgroundImage: `url(${heroImage})`,
                    }}
                  >
                    {" "}
                  </div>
                </div>
              </div>
            </div>
            {id === "suv" ? (
              <div class="container c-pt-lg">
                <h2 class="c-my-lg">Discover more about renting a SUV</h2>
                <div class="seo-content__container js_seo-content__container">
                  <h2>Rent an SUV wherever you go</h2>

                  <h3>SUV rentals in a few clicks</h3>

                  <p>
                    Whether you’re visiting or living in over 20 US states, you
                    can easily and quickly rent an SUV with the Getaround
                    carsharing app. Some of our carsharers like the convenience
                    of getting an SUV when family comes to town. Others love
                    getting off the plane in a new city and hopping in a 2-door
                    SUV. Whatever your reason, we’ve got the SUV for you.
                  </p>

                  <h3>Get the app and rent your SUV</h3>

                  <p>
                    First-time carsharers can’t believe how simple it is to find
                    the perfect SUV to match their plans. Just{" "}
                    <a href="https://getaround.com/how-it-works/mobile">
                      download the Getaround app
                    </a>{" "}
                    on an iOS or Android device, sign in and start searching.
                    You can rent an SUV by the hour, day or even week. Either
                    reserve your vehicle on your desktop, laptop or smartphone
                    before you go or book it with your phone while you’re on the
                    go.
                  </p>

                  <p>
                    Lucky for you, Getaround is the only carsharing service with
                    contactless Getaround Connect® technology. So, after
                    choosing and reserving your SUV rental on the app, your
                    phone also unlocks it. Nothing could be easier, and there
                    are no keys to exchange or misplace!
                  </p>

                  <h3>Customize your SUV rental</h3>

                  <p>
                    To better tailor your ride, you have lots of options. Enter
                    the date and times you want, then hit the “More filters”
                    button. This is where the fun begins!
                    <br></br>
                    Click on the vehicle type “SUV/Jeep” and keep scrolling to
                    see more choices. For example, you can specify the vehicle
                    class: economy, luxury or performance. Depending on your
                    activities, it’s also a great idea to pick the right
                    drivetrain. You might prefer to rent an all-wheel drive or
                    4-wheel drive SUV if you’re heading to the mountains for
                    some skiing or a desert destination. You can even choose
                    front-wheel drive or rear-wheel drive if that’s an important
                    feature for you.
                  </p>

                  <h3>Rent an SUV to fit your cargo</h3>

                  <p>
                    Next, think about your cargo. Are you planning on bringing
                    your dogs and that big canoe? Then choose “Roof rack” and
                    “Pet friendly.” If you’re taking the bikes up out to the
                    trails, don’t forget to choose “Bike rack.” And you’ll
                    probably want to tune into your favorite playlists on the
                    way, so check the “Bluetooth” and/or “CarPlay/Android Auto”
                    options, too.
                    <br></br>
                    Once you’ve filtered all the SUVs in your area, you can also
                    pick a convertible, 2-door or 4-door. Just depends on what
                    you’re carting around that day (cargo, kids, party
                    supplies…) and where you’re going.
                  </p>

                  <h3>Getaround carsharing is caring</h3>

                  <p>
                    SUVs keep you are your passengers safer on the road, but did
                    you know they can also be environmentally conscious? Not
                    only does carsharing make motor vehicle travel more
                    economical, you can also opt to go green on your next trip.
                    Just click the “Green” option and we’ll find you a hybrid or
                    electric SUV. A quick little gesture for the planet – and
                    you’ll save on gas to boot.
                    <br></br>
                    So, welcome to the carsharing experience you’ll always come
                    back for. And browse all the great SUVs waiting to go for a
                    ride!
                  </p>
                </div>
                <div class="c-mt-lg">
                  <h3>Getaround around the world</h3>
                  <div
                    class="horizontal_scroll_list js_horizontal_scroll_list_seo_links c-mb-sm horizontal_scroll_list--enhanced"
                    style={{ height: "28px" }}
                  >
                    <div
                      class="horizontal_scroll_list__gradient horizontal_scroll_list__gradient--left js_horizontal_scroll_list_gradient_left"
                      data-trigger-scroll="-1"
                    ></div>
                    <div
                      class="horizontal_scroll_list__inner js_horizontal_scroll_list_inner"
                      style={{ height: "48px" }}
                    >
                      <div class="cobalt-PillGroup">
                        <div class="cobalt-PillGroup__Item">
                          <a class="link_no_style" href="/car-rental#compare">
                            <div class="cobalt-Pill">Car rental comparison</div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="horizontal_scroll_list__gradient horizontal_scroll_list__gradient--right js_horizontal_scroll_list_gradient_right"
                      data-trigger-scroll="1"
                    ></div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="container c-pt-lg">
                <h2 class="c-my-lg">Discover more about renting a pickup</h2>
                <div class="seo-content__container js_seo-content__container"></div>
                <div class="c-mt-lg">
                  <h3>Getaround around the world</h3>
                  <div
                    class="horizontal_scroll_list js_horizontal_scroll_list_seo_links c-mb-sm horizontal_scroll_list--enhanced"
                    style={{ height: "28px" }}
                  >
                    <div
                      class="horizontal_scroll_list__gradient horizontal_scroll_list__gradient--left js_horizontal_scroll_list_gradient_left"
                      data-trigger-scroll="-1"
                    ></div>
                    <div
                      class="horizontal_scroll_list__inner js_horizontal_scroll_list_inner"
                      style={{ height: "45px" }}
                    >
                      <div class="cobalt-PillGroup">
                        <div class="cobalt-PillGroup__Item">
                          <a class="link_no_style">
                            <div class="cobalt-Pill">Car rental comparison</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ReusableFeedback feedback={feedback?.result}/>
            <ReusableFaq />
          </div>
        </div>
      </body>
    </div>
  );
};

export default TypeCars;
