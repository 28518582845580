import React, { useContext } from "react";
import { DataContext } from "../../../ContextApi";

const EditCarReplace = () => {
    const {rentYourCar}=useContext(DataContext)
  return (
<>
<div
          className="cobalt-CheckmarkField"
          data-form-checkmark-method="is_pro"
        >
          <div>
            {rentYourCar?.cancel_rule.map((val) => (
              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  required
                  //   checked={selectDataCaseThree.cancelation?.includes("1")}
                  value="1"
                  //   onChange={(e) =>
                  //     handleChange(
                  //       e,
                  //       setSelectDataCaseThree,
                  //       selectDataCaseThree,
                  //       "caseThree"
                  //     )
                  //   }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">Flexible</span> */}
                  <div className="discount-info">
                    <strong>{val?.cancellation_rule_title}</strong>
                    <p>{val?.cancellation_rule}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
</>  
);
};

export default EditCarReplace;
