import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../ContextApi";
import { getEditCarDetail, hardlyLogout, UpdateCar } from "../../../api";

const EditCreateCar = ({id,handleCloseEditModal,formData}) => {
    const {rentYourCar,openDialog}=useContext(DataContext)
     // State to hold form data
  const [formDataNew, setFormDataNew] = useState({
    make: "",
    model: "",
    release_year: "",
    mileage: "",
    car_type:"",
    fuel_type: "",
   
  });
  const [loader, setLoader] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataNew({
      ...formDataNew,
      [name]: value,
    });
  };
  useEffect(() => {
    setFormDataNew({...formDataNew,   make: formData?.make_id,
    model: formData?.vehicle_model,
    release_year: formData?.vehicle_year,
    mileage: formData?.vehicle_milage,
    car_type:formData?.vehicle_type_id,
    fuel_type: formData?.vehicle_fuel_type_id,
     })
     }, [formData]);
  const handleFormSubmit = () => {
    console.log(formData);
    let payload = {
      ...formData,
      
    };

    UpdateCar(id, payload, handleCloseEditModal, openDialog);
  };
  return (
    <>
    <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Make
    </label>
    <select
      name="make"
        value={formData.make}
        onChange={handleInputChange}
      id="car_model_estimation_car_brand_id"
      class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
      data-url-for-models="/car_models/models"
    >
      {rentYourCar?.make?.map((val) => (
        <option value={val.id}>{val.title}</option>
      ))}
    </select>
  </div>
  <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Model
    </label>
    <select
      name="model"
        value={formData.model}
        onChange={handleInputChange}
      id=" car_model_estimation_car_model_id"
      class=" owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_model_select js_submit_on_change js_show_placeholder js_submit_if_other"
    >
      {/* <option disabled="disabled" selected="selected" value="">
            Model
          </option> */}
      {rentYourCar?.model?.map((val) => (
        <option value={val.id}>{val.title}</option>
      ))}
    </select>
  </div>
  <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Year
    </label>
    <select
      name="release_year"
        value={formData.release_year}
        onChange={handleInputChange}
      id="car_model_estimation_release_year"
      class="owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_release_year_select js_submit_on_change js_show_placeholder"
    >
      {rentYourCar?.year?.map((val) => (
        <option value={val.year_id}>{val.year}</option>
      ))}
    </select>
  </div>
  <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Milage
    </label>
    <select
      name="mileage"
        value={formData.mileage}
        onChange={handleInputChange}
      id="car_model_estimation_mileage"
      class="owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
    >
      {rentYourCar?.milage?.map((val) => (
        <option value={val?.milage_id}>{val?.milage}</option>
      ))}{" "}
    </select>
  </div>

  <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      fuel{" "}
    </label>
    <select
      class="cobalt-SelectField__Input"
      name="fuel_type"
        value={formData.fuel_type}
        onChange={handleInputChange}
    >
      {rentYourCar?.fuel_type?.map((val) => (
        <option value={val.fuel_type_id}>{val.fuel_type}</option>
      ))}
    </select>
  </div>
  <div class="cobalt-FormField" data-form-field-method="brand_id">
    <label className="cobalt-FormField__Label"> Vehicle Type</label>
    <div className="cobalt-CheckablePillsGroup">
      {rentYourCar?.vehcile_type?.map((val) => (
        <div
          className="cobalt-CheckablePillField"
          key={val.vehicle_type_id}
        >
          <label className="cobalt-CheckablePillField__LabelWrapper">
            <input
              id={`checkable-pill-${val.vehicle_type_id}`}
              className="cobalt-CheckablePillField__Input"
              type="radio"
               checked={
                 formData.car_type == val.vehicle_type_id
               }
               value={val?.vehicle_type_id}
               onChange={handleInputChange}

              name="car_type"
            />
            <span
              style={{ height: "60px" }}
              class="cobalt-CheckablePillField__Label"
            >
              <span
                style={{ width: "50px", height: "50px" }}
                class="cobalt-Icon cobalt-Icon--car"
              >
                <span class="cobalt-Icon cobalt-Icon--car">
                  <img
                    src={val?.image_url}
                    style={{ width: "50px", height: "50px" }}
                  />
                </span>
              </span>
              {/* {val?.vehicle_type} */}
            </span>{" "}
          </label>
        </div>
      ))}
      <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div>
    </div>
  </div>  

</>
)
};

export default EditCreateCar;
