import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverPricing = () => {
  const { setSelectDriverCase, selectDriverCase, selectDataCaseFour } =
    useContext(DataContext);
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const [selectRadioId, setSelectedRadioId] = useState(
    selectDataCaseFour?.driver_option_id[0]
  );

  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };
  console.log("selectedfour", selectDataCaseFour?.driver_option_id[0]);
  return (
    <>
      <div>
        <div
          id="3"
          class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main"
        >
          <div class="c-text-section-heading c-mb-xs">List my car</div>
          <div class="c-text-title-md c-mb-lg">Pricing Conditions</div>

          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
              Price per day
            </label>
            <div
              style={{
                display: "flex",
                fontSize: "25px",
                fontWeight: "800",
                gap: "10px",
              }}
              class="cobalt-TextField"
            >
              $
              <input
                required
                class="cobalt-TextField__Input"
                type="number"
                name="priceDay"
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  width: "20%",
                }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "+") {
                    e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only positive numbers
                  if (value >= 0) {
                    handleChange(
                      e,
                      setSelectDriverCase,
                      selectDriverCase,
                      "caseDriver"
                    );
                  }
                }}
                value={selectDriverCase.priceDay}
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
              Price for a weekend
            </label>
            <div
              style={{
                display: "flex",
                fontSize: "25px",
                fontWeight: "800",
                gap: "10px",
              }}
              class="cobalt-TextField"
            >
              $
              <input
                required
                class="cobalt-TextField__Input"
                type="number"
                name="priceWeekend"
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  width: "20%",
                }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "+") {
                    e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only positive numbers
                  if (value >= 0) {
                    handleChange(
                      e,
                      setSelectDriverCase,
                      selectDriverCase,
                      "caseDriver"
                    );
                  }
                }}
                value={selectDriverCase.priceWeekend}
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
              Price outside the region & overnight
            </label>
            <div
              style={{
                display: "flex",
                fontSize: "25px",
                fontWeight: "800",
                gap: "10px",
              }}
              class="cobalt-TextField"
            >
              $
              <input
                required
                class="cobalt-TextField__Input"
                type="number"
                name="priceOutRegion"
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  width: "20%",
                }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "+") {
                    e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only positive numbers
                  if (value >= 0) {
                    handleChange(
                      e,
                      setSelectDriverCase,
                      selectDriverCase,
                      "caseDriver"
                    );
                  }
                }}
                value={selectDriverCase.priceOutRegion}
              />
            </div>
          </div>
          <div class="cobalt-Note cobalt-Note--fullWidth">
            This information allows us to ensure the quality and safety of our
            fleet.
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverPricing;
