import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationSearch from "../../../ReusableComponent/LocationSearch";
import { DataContext } from "../../../ContextApi";
import { registerCar } from "../../../api";
import { handleChange } from "../../../ExportFiles";

const EarnMoney = () => {
  const navigate = useNavigate();
  const {
    setSearchVehicles,
    rentYourCar,
    selectDataCaseOne,
    setSelectDataCaseOne,
    setRegisterId,
    registerId,
    openDialog,
  } = useContext(DataContext);

  const [location, SetLocation] = useState({ lat: "", lng: "", location: "" });
  
  const handleGetStarted = () => {
    let payload = {
      ...selectDataCaseOne
    };
    registerCar(payload, navigate, openDialog);
  };

  useEffect(() => {
    SetLocation({ lat: "", lng: "", location: "" });
    setRegisterId("");
  }, []);

  return (
    <header class="layer owner_homepage__hero_layer owner_landing_hero__wrapper">
      <div class="owner_homepage__hero_image_container"></div>
      <div class="owner_homepage__hero_content_container">
        <div class="owner_homepage__hero_text">
          <h1 class="owner_homepage__hero_title">
            Earn money by sharing your car with locals
          </h1>
          <svg style={{ display: "none" }} xmlns="http://www.w3.org/2000/svg">
            <defs>
              <symbol
                id="svg_icons-marker"
                class="svg_from_sprite"
                viewBox="0 0 24 24"
              >
                <g fill-rule="evenodd" stroke="none">
                  <path d="M19,10 C19,15.0914792 13.07625,21.0728333 12.8254167,21.3249792 C12.5964583,21.5527708 12.2989583,21.6666667 12,21.6666667 C11.7010417,21.6666667 11.4035417,21.5527708 11.1745833,21.3249792 C10.9222917,21.0728333 5,15.0914792 5,10 C5,6.14022917 8.13979167,3 12,3 C15.8602083,3 19,6.14022917 19,10 Z M12,13.5 C13.9322917,13.5 15.5,11.9330208 15.5,10 C15.5,8.067125 13.9322917,6.5 12,6.5 C10.0677083,6.5 8.5,8.067125 8.5,10 C8.5,11.9330208 10.0677083,13.5 12,13.5 Z"></path>
                </g>
              </symbol>
            </defs>
          </svg>
          <div>
            <div class="owner_homepage_hero_estimation_form__container js_car_model_estimation_form__container">
              <select
                name="carBrand"
                id="car_model_estimation_car_brand_id"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                data-url-for-models="/car_models/models"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.carBrand}
              >
                {rentYourCar?.make?.map((val) => (
                  <option value={val.id}>{val.title}</option>
                ))}
              </select>
              <select
                name="carModel"
                id="car_model_estimation_car_model_id"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_model_select js_submit_on_change js_show_placeholder js_submit_if_other"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.carModel}
              >
                {/* <option disabled="disabled" selected="selected" value="">
                  Model
                </option> */}
                {rentYourCar?.model?.map((val) => (
                  <option value={val.id}>{val.title}</option>
                ))}
              </select>
              <select
                name="releaseYear"
                id="car_model_estimation_release_year"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_release_year_select js_submit_on_change js_show_placeholder"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.releaseYear}
              >
                {rentYourCar?.year?.map((val) => (
                  <option value={val.year_id}>{val.year}</option>
                ))}
              </select>
              <select
                name="milage"
                id="car_model_estimation_mileage"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne.milage}
              >
                {rentYourCar?.milage?.map((val) => (
                  <option value={val?.milage_id}>{val?.milage}</option>
                ))}{" "}
              </select>
              <select
                id="car_model_estimation_mileage"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
                data-url-for-models="/car_models/models"
                required="required"
                name="fuelType"
                value={selectDataCaseOne.fuelType}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
              >
                {rentYourCar?.fuel_type?.map((val) => (
                  <option value={val.fuel_type_id}>{val.fuel_type}</option>

                ))}
              </select>
              <select
                id="car_model_estimation_mileage"
                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
                data-url-for-models="/car_models/models"
                required="required"
                name="transmissionType"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseOne,
                    selectDataCaseOne,
                    "caseOne"
                  )
                }
                value={selectDataCaseOne?.transmissionType}
              >
                {rentYourCar?.transmission?.map((val) => (
                  <option value={val.transmission_id}>
                    {val?.transmission_type}
                  </option>
                ))}
              </select>
              {/* <div class="owner_homepage_hero_estimation_form__item owner_homepage_hero_estimation_form__item--fullWidthXs">
                <div class="js_AddressAutocomplete-container">
                  <div class="Address-autocomplete Address-autocomplete--powered-by-google">
                    <div
                      class="cobalt-Autocomplete"
                      data-reach-combobox=""
                      data-state="idle"
                    >
                      <div class="cobalt-Autocomplete__input-wrapper ">
                        <div class="cobalt-TextField cobalt-TextField--withIcon c-mb-sm">
                          <LocationSearch
                            value={true}
                            setSearchVehicles={setSearchVehicles}
                            searchPage={true}
                            SetLocation={SetLocation}
                          />
                          <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder">
                <div className="c-mb-sm ">
                  <input
                    type="number"
                    name="price"
                    required
                    value={selectDataCaseOne?.price}
                    style={{ paddingLeft: "8px" }}
                    placeholder="Enter price"
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDataCaseOne,
                        selectDataCaseOne,
                        "caseOne"
                      )
                    }
                  />
                </div>
              </div> */}
            </div>

            <div class="owner_homepage_hero_estimation_form_result__container">
              <div class="js_estimation_result_placeholder hidden_content">
                <div class="cobalt-Note cobalt-Note--fullWidth text_center c-text-body-md c-text-base">
                  Please pick your car model to start a listing.
                </div>
              </div>
              <div
                class="owner_homepage_hero_estimation_form_maximum_earnings__container js_maximum_earnings_disclaimer"
                hidden="true"
              >
                <p class="c-text-section-heading c-mb-none text_center">
                  Up to
                </p>
              </div>
              <div class="js_car_model_estimation_result">
                <div class="owner_homepage_hero_estimation_form_result__container">
                  {/* {location?.location && selectDataCaseOne?.price ? ( */}
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <button
                      class="cob-Button cob-Button__default cob-Button__default--primary js_rent_my_car js_rent_my_car_banner"
                      onClick={handleGetStarted}
                    >
                      Get Started
                    </button>
                  </div>
                  {/* ) : (
                    <div class="cobalt-Note cobalt-Note--fullWidth ">
                      {message}
                      <div class="js_car_non_eligible"></div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="estimate_about_with_open" class="c-hidden" data-closeable="true">
        <div class="js_cobalt-inline-modal__title">
          Estimation of your earnings
        </div>
        <div
          class="js_cobalt-inline-modal__body"
          style={{ minHeight: "inherit" }}
        >
          <p class="c-text-body-md">
            This is an average estimate based on the price recommended for your
            car, renting it <span class="c-font-bold">20 days</span> per month.{" "}
            <span class="c-font-bold">with the Overlo Connect box</span> and
            that your car's insurance category is accepted on the Overlo
            platform. What you actually earn varies depending on a range of
            factors, such as your location and the time of the year. Check if
            your car is eligible to Overlo Connect on{" "}
            <a href="/connect-owner?force_car_model_eligibility=true">
              this page
            </a>
            .
          </p>
        </div>
      </div>
      <div
        id="estimate_about_with_standard"
        class="c-hidden"
        data-closeable="true"
      >
        <div class="js_cobalt-inline-modal__title">
          Estimation of your earnings
        </div>
        <div
          class="js_cobalt-inline-modal__body"
          style={{ minHeight: "inherit" }}
        >
          <p class="c-text-body-md c-text-base">
            Average estimate based on the price recommended for your car by
            Overlo, renting it <span class="c-font-bold">15 days</span> per
            month. What you actually earn varies depending on a range of
            factors, such as your location and the time of the year.
          </p>
        </div>
      </div>
    </header>
  );
};

export default EarnMoney;
