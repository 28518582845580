import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const DriverLisence = () => {
  const { rentYourCar, selectDriverCase, setSelectDriverCase } =
    useContext(DataContext);
  const [selectData, setSelectData] = useState({
    carBrand: "",
    carModel: "",
  });

  useEffect(() => {
    if (rentYourCar?.make?.length > 0 && rentYourCar?.model?.length > 0) {
      setSelectData({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
      });
    }
  }, [rentYourCar]);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my driver</div>
        <div class="c-text-title-md c-mb-lg">Driver's License</div>
        <div class="c-mb-lg">
          <div class="cobalt-FormField" data-form-field-method="brand_id">
            <label class="cobalt-FormField__Label" for="car_brand_id">
              Type of driver's license
            </label>
            <div class="cobalt-SelectField">
              <select
                class="js_car_brand cobalt-SelectField__Input"
                data-url-for-models="/car_models/models"
                required="required"
                name="licenseCategory"
                id="car_brand_id"
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDriverCase,
                    selectDriverCase,
                    "caseDriver"
                  )
                }
                value={selectDriverCase.licenseCategory}
              >
                {rentYourCar?.driver?.license_types?.map((val) => (
                  <option value={val.license_type_id}>
                    {val.license_type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="brand_id">

          <label class="cobalt-FormField__Label" for="car_energy">
          Country of issuance

          </label>
          <div class="cobalt-SelectField">
            <select
              class="cobalt-SelectField__Input"
              name="licenseIssueCountry"
              value={selectDriverCase?.licenseIssueCountry}
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDriverCase,
                  selectDriverCase,
                  "caseDriver"
                )
              }
            >
              {rentYourCar?.all_country?.map((val) => (
                <option value={val.id}>{val.name}</option>
              ))}
            </select>
          </div>
          </div>
          {/* <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
              Plate number
            </label>
            <div class="cobalt-TextField">
              <input
                required
                class="cobalt-TextField__Input"
                type="text"
                name="plate_number"
                id="car_plate_number"
                //   onChange={(e) =>
                //     handleChange(
                //       e,
                //       setSelectDataCaseTwo,
                //       selectDataCaseTwo,
                //       "caseTwo"
                //     )
                //   }
                //   value={selectDataCaseTwo.plate_number}
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
            License number
            </label>
            <div class="cobalt-TextField">
              <input
                required
                class="cobalt-TextField__Input"
                type="number"
                name="plate_number"
                id="car_plate_number"
                //   onChange={(e) =>
                //     handleChange(
                //       e,
                //       setSelectDataCaseTwo,
                //       selectDataCaseTwo,
                //       "caseTwo"
                //     )
                //   }
                //   value={selectDataCaseTwo.plate_number}
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
            Date of issue
            </label>
            <div class="cobalt-TextField">
              <input
                required
                class="cobalt-TextField__Input"
                type="date"
                name="plate_number"
                id="car_plate_number"
                //   onChange={(e) =>
                //     handleChange(
                //       e,
                //       setSelectDataCaseTwo,
                //       selectDataCaseTwo,
                //       "caseTwo"
                //     )
                //   }
                //   value={selectDataCaseTwo.plate_number}
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
            Date of issue
            </label>
            <div class="cobalt-TextField">
              <input
                required
                class="cobalt-TextField__Input"
                type="date"
                name="plate_number"
                id="car_plate_number"
                //   onChange={(e) =>
                //     handleChange(
                //       e,
                //       setSelectDataCaseTwo,
                //       selectDataCaseTwo,
                //       "caseTwo"
                //     )
                //   }
                //   value={selectDataCaseTwo.plate_number}
              />
            </div>
          </div> */}
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          Completing your listing will only take a few minutes ⏱️
        </div>
      </div>
    </>
  );
};

export default DriverLisence;
