import React, { useContext, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

import "../../../search.css";
import {
    registerDriver,
  updateStep0,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
} from "../../../../api";
import { DataContext } from "../../../../ContextApi";

import StepModal from "../Register/StepModal";
import DriverLisence from "./DriverLisense";
import DriverLisenceNext from "./DriverLisenceNext";
import DriverSkills from "./DriverSkills";
import DriverSkillsNext from "./DriverSkillsNext";
import DriverPricing from "./DriverPricing";
import DriverPricingNext from "./DriverPricingNext";
import DriverCancelation from "./DriverCancelation";
import DriverPromotion from "./DriverPromotions";
import DriverDuration from "./DriverDuration";
import DriverAddress from "./DriverAddress";
import DriverDescription from "./DriverDescription";
import DriverImageUpload from "./DriverImageUpload";
const MainDriver = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    selectDataCaseOne,
    selectDataCaseTwo,
    selectDataCaseThree,
    selectDataCaseFour,
    selectDataCaseFiveLocation,
    setInputValue,
    setSelectDataCaseFour,rentYourCar,selectDriverCase,
    openDialog,
    imageURLs,
    latLngRegion,
  } = useContext(DataContext);
  const [customWidth, setCustomWidth] = useState("0%");
  const [loading, setLoading] = useState(false);
  const getBasePath = (path) => {
    const match = path.match(/^(\/add_driver\/\d+)/);
    return match ? match[1] : "";
  };
  const match = useMatch("/add_driver/:id/*");
  const step = match.params["*"];
  const id = match ? match.params.id : null;

  console.log("idddfinal", id);

  // console.log("idddfinal",id);

  // Check if the step is 'step_billing'
  const isStepBilling = step === "step_billing";
  console.log("customWidth", isStepBilling);
  const calculateWidth = (path) => {
    const basePath = getBasePath(path);
    switch (path) {
      case `${basePath}/driver_license`:
        return "7%";
      case `${basePath}/driver_license_next`:
        return "14%";
      case `${basePath}/driver_skills`:
        return "24%";
      case `${basePath}/driver_skills_next`:
        return "34%";
      case `${basePath}/driver_pricing`:
        return "44%";
      case `${basePath}/driver_pricing_next`:
        return "54%";
      case `${basePath}/driver_cancelation`:
        return "64%";
      case `${basePath}/driver_promotion`:
        return "74%";
      case `${basePath}/driver_duration`:
        return "84%";
      case `${basePath}/driver_address`:
        return "90%";
      case `${basePath}/driver_description`:
        return "95%";
        case `${basePath}/driver_images`:
        return "98%";
      default:
        return "0%";
    }
  };

  useEffect(() => {
    const path = location.pathname;
    setCustomWidth(calculateWidth(path));
  }, [location.pathname]);

  const renderFooterButtons = () => {
    const path = location.pathname;
    const basePath = getBasePath(path);

    if (path === `${basePath}/driver_images`) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                // onClick={handleNextClick}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                // onClick={handleNextClick}
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
        </>
      );
    } else if (path === `${basePath}/step_modal`) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div class="c-flex c-justify-end">
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                // onClick={handleNextClick}
              />
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <input
                type="submit"
                name="commit"
                value={loading ? "Loading..." : "Confirm"}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                // onClick={handleNextClick}
              />
            </div>
          </div>
        </>
      );
    } else if (
      path === `${basePath}/driver_license` ||
      path === `${basePath}/driver_pricing` ||
      path === `${basePath}/driver_pricing_next` ||
      path === `${basePath}/driver_cancelation` ||
      path === `${basePath}/driver_promotion` ||
      path === `${basePath}/driver_duration` ||
      path === `${basePath}/driver_address` ||
      path === `${basePath}/driver_license_next` ||
      path === `${basePath}/driver_skills` ||
      path === `${basePath}/driver_description` ||

      
      path === `${basePath}/driver_skills_next`
    ) {
      return (
        <>
          <div class="cobalt-Card__Section cobalt-Card__Section--divided hidden-xs js_car-wizard__footer c-rounded-b-xl">
            <div
              class={
                isStepBilling
                  ? "c-flex c-justify-between"
                  : "c-flex c-justify-end"
              }
            >
              {isStepBilling && (
                <div>
                  {" "}
                  <a
                    class="cob-Button cob-Button__ghost c-mr-sm"
                    onClick={handleSkipClick}
                  >
                    {/* <span class="cob-Button__Icon cob-Button__Icon--left">
                    <span class="cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </span>
                  </span> */}
                    <span>Skip</span>
                  </a>
                </div>
              )}
              <div>
                <a
                  class="cob-Button cob-Button__ghost c-mr-sm"
                  onClick={handlePreviousClick}
                >
                  <span class="cob-Button__Icon cob-Button__Icon--left">
                    <span class="cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  <span>Previous</span>
                </a>
                <input
                  type="submit"
                  name="commit"
                  value="Next"
                  // onClick={handleNextClick}
                  class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
                />
              </div>
            </div>
          </div>
          <div class="car_wizard__sticky_cta_container js_car-wizard__footer">
            <div class="c-flex c-justify-end">
              <a
                class="cob-Button cob-Button__ghost c-mr-sm"
                onClick={handlePreviousClick}
              >
                <span class="cob-Button__Icon cob-Button__Icon--left">
                  <span class="cobalt-Icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                      ></path>
                    </svg>
                  </span>
                </span>
                <span>Previous</span>
              </a>
              <input
                type="submit"
                name="commit"
                value="Next"
                // onClick={handleNextClick}
                class="cob-Button cob-Button__default cob-Button__default--primary js_ncf_next_btn"
              />
            </div>
          </div>
        </>
      );
    }
  };
  const handleNextClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);
    const billing = localStorage.getItem("is_billing_address");

    switch (path) {
      case `${basePath}/driver_license`:

        navigate(`${basePath}/driver_license_next`);
        break;
      case `${basePath}/driver_license_next`:

        navigate(`${basePath}/driver_skills`);
        break;
      case `${basePath}/driver_skills`:

        navigate(`${basePath}/driver_skills_next`);
        break;
      case `${basePath}/driver_skills_next`:

        navigate(`${basePath}/driver_pricing`);
        break;
      case `${basePath}/driver_pricing`:

        navigate(`${basePath}/driver_pricing_next`);
        break;
      case `${basePath}/driver_pricing_next`:

      
    
         navigate(`${basePath}/driver_cancelation`);
        break;
      case `${basePath}/driver_cancelation`:

        navigate(`${basePath}/driver_promotion`);
        break;
      case `${basePath}/driver_promotion`:

        navigate(`${basePath}/driver_duration`);
        break;
      case `${basePath}/driver_duration`:
        if (billing == "1") {

        navigate(`${basePath}/driver_description`);
        }
        else
        {
          navigate(`${basePath}/driver_address`);

        }
        break;
      case `${basePath}/driver_address`:

        navigate(`${basePath}/driver_description`);
        break;
        case `${basePath}/driver_description`:
    
            navigate(`${basePath}/driver_images`);
            break;
            case `${basePath}/driver_images`:
    
            let stateIdsInRentYourCar = rentYourCar?.state?.filter(region => selectDriverCase.states.includes(region.id))
            .map(region => {
                return {
                    ...region,
                    price: selectDriverCase?.[region.short_name]
                };
            });
        let payload = {...selectDriverCase,states: stateIdsInRentYourCar };
        registerDriver(payload, openDialog, navigate);
        console.log("stateIdsInRentYourCar",stateIdsInRentYourCar);
        break;

      default:
        break;
    }
  };
  const handleSkipClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);

    switch (path) {
      case `${basePath}/step_billing`:
        navigate(`${basePath}/image_upload`);
        setSelectDataCaseFour(() => ({
          ...selectDataCaseFour,
          address: "",
          address2: "",

          zip_code: "",
          city: "",
          country: "",

          state: "",
        }));
        break;

      default:
        break;
    }
  };
  const handlePreviousClick = (e) => {
    e.preventDefault();
    const path = location.pathname;
    const basePath = getBasePath(path);
    const billing = localStorage.getItem("is_billing_address");

    switch (path) {
      case `${basePath}/driver_license`:
        navigate(`/add_driver`);
        break;
      case `${basePath}/driver_license_next`:
        navigate(`${basePath}/driver_license`);
        break;
      case `${basePath}/driver_skills`:
        navigate(`${basePath}/driver_license_next`);
        break;
      case `${basePath}/driver_skills_next`:
        navigate(`${basePath}/driver_skills`);
        break;
      // case `${basePath}/step_energy`:
      //   navigate(`${basePath}/step_milage`);
      //   break;
      case `${basePath}/driver_pricing`:
        navigate(`${basePath}/driver_skills_next`);
        break;
      case `${basePath}/driver_pricing_next`:
        navigate(`${basePath}/driver_pricing`);
        break;

      case `${basePath}/driver_cancelation`:
        navigate(`${basePath}/driver_pricing_next`);
        break;
      case `${basePath}/driver_promotion`:
        navigate(`${basePath}/driver_cancelation`);
        break;
      case `${basePath}/driver_duration`:
        navigate(`${basePath}/driver_promotion`);
        break;

      case `${basePath}/driver_address`:
        navigate(`${basePath}/driver_duration`);
        break;
      case `${basePath}/driver_description`:
        if (billing == "1") {
        navigate(`${basePath}/driver_duration`);
        }
        else
        {
          navigate(`${basePath}/driver_address`);
        }
        break;
        case `${basePath}/driver_images`:

        navigate(`${basePath}/driver_description`);
        break;
      case `${basePath}/step_features`:
        navigate(`${basePath}/reduction`);
        break;
      // case `${basePath}/step_pro`:
      //   navigate(`${basePath}/step_features`);
      //   break;

      case `${basePath}/step_billing`:
        navigate(`${basePath}/step_features`);
        break;

      case `${basePath}/step_contact`:
        if (billing == "1") {
          navigate(`${basePath}/cancelation_type_insurance`);
        } else {
          navigate(`${basePath}/cancelation_type_insurance`);
        }
        break;
      // case `${basePath}/step_maintain`:
      //   navigate(`${basePath}/step_contact`);
      //   break;
      // case `${basePath}/step_standard`:
      //   navigate(`${basePath}/step_contact`);
      //   break;
      // case `${basePath}/step_price`:
      //   navigate(`${basePath}/step_standard`);
      //   break;
      // case `${basePath}/step_price_education`:
      //   navigate(`${basePath}/step_price`);
      //   break;
      case `${basePath}/image_upload`:
        navigate(`${basePath}/step_billing`);
        break;
      default:
        break;
    }
  };
  return (
    <div class="full_width_content default_background">
      <div class="container">
        <div class="car_wizard">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 no-outer-gutter-xs">
              <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                <form
                  class="new_car"
                  onSubmit={handleNextClick}
                  id="new_car"
                  action="/car_wizards/1509611/steps/model"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <div class="cobalt-Card__Section cobalt-Card__Section--tight c-rounded-t-xl">
                    <div class="car_wizard__progression_bar">
                      <div
                        class="car_wizard__progression_bar_inner"
                        style={{ width: customWidth }}
                      ></div>
                    </div>
                  </div>
                  <Routes>
                    {/* <Route
                      path="step_modal"
                      element={
                        <div>
                          {" "}
                          <StepModal />
                        </div>
                      }
                    /> */}
                    <Route
                      path="driver_license"
                      element={
                        <div>
                          {" "}
                          <DriverLisence />
                        </div>
                      }
                    />
                    <Route
                      path="driver_license_next"
                      element={
                        <div>
                          {" "}
                          <DriverLisenceNext />
                        </div>
                      }
                    />
                    <Route
                      path="driver_skills"
                      element={
                        <div>
                          {" "}
                          <DriverSkills />
                        </div>
                      }
                    />
                    <Route
                      path="driver_skills_next"
                      element={
                        <div>
                          {" "}
                          <DriverSkillsNext />
                        </div>
                      }
                    />
                    <Route
                      path="driver_pricing"
                      element={
                        <div>
                          {" "}
                          <DriverPricing />
                        </div>
                      }
                    />
                    <Route
                      path="driver_pricing_next"
                      element={
                        <div>
                          {" "}
                          <DriverPricingNext />
                        </div>
                      }
                    />
                    <Route
                      path="driver_cancelation"
                      element={
                        <div>
                          {" "}
                          <DriverCancelation />
                        </div>
                      }
                    />
                    <Route
                      path="driver_promotion"
                      element={
                        <div>
                          {" "}
                          <DriverPromotion />
                        </div>
                      }
                    />
                    <Route
                      path="driver_duration"
                      element={
                        <div>
                          {" "}
                          <DriverDuration />
                        </div>
                      }
                    />
                    <Route
                      path="driver_address"
                      element={
                        <div>
                          {" "}
                          <DriverAddress />
                        </div>
                      }
                    />
                    <Route
                      path="driver_description"
                      element={
                        <div>
                          {" "}
                          <DriverDescription />
                        </div>
                      }
                    />
                     <Route
                      path="driver_images"
                      element={
                        <div>
                          {" "}
                          <DriverImageUpload />
                        </div>
                      }
                    />
                  </Routes>
                  {renderFooterButtons()}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDriver;
