import React from "react";

const EditConfirmation = () => {
  return (
<div
          className="cobalt-CheckmarkField"
          data-form-checkmark-method="is_pro"
        >
          {/* {rentYourCar?.booking_option?.map((val) => ( */}
          <div>
            <div
              className={`option-item`}
              // onClick={() => handleOptionChange('1')}
            >
              <div className="option-content">
                <span className="option-title">Use instant booking</span>
                <p className="option-description">
                  Travelers can book automatically.
                </p>
              </div>
              <span className="option-icon">⚡</span>
            </div>

            <div
              className={`option-item selected`}
              // onClick={() => handleOptionChange('2')}
            >
              <div className="option-content">
                <span className="option-title">Accept or decline requests</span>
                <p className="option-description">
                  Travelers must make a reservation request.
                </p>
              </div>
              <span className="option-icon">💬</span>
            </div>
          </div>
          {/* ))} */}
        </div>  
);
};

export default EditConfirmation;
