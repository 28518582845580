
export const handleOverAllFilter = (setFilterValue,key, value) => {
  
    setFilterValue((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  // val is the value of selected 
  // setFilterValue
  export const handleAllFilters = (key,val,setFilterValue,filterValue) => {
    let updatedSelected;
    if (filterValue.includes(val)) {
      // If the value is already selected, remove it from the array
      updatedSelected = filterValue.filter((type) => type !== val);
    } else {
      // Otherwise, add it to the array
      updatedSelected = [...filterValue, val];
    }
  
    // Update the overall filter with the new selected vehicle types
    handleOverAllFilter(setFilterValue, key, updatedSelected);
  
    return updatedSelected;
  
  };

//   export const handleDate = (date, setFilterValue, filterValue, name) => {
    
// console.log("handleDate",date)    

//     // Update state
//     setFilterValue({
//       ...filterValue,
//       [name]: date
//     });
  
//     // Store formatted date in sessionStorage
//     sessionStorage.setItem(name, date);
//   };
  
  
export const handleDate = (date, setFilterValue, filterValue, name, openDialog, handleSeach) => {
  // Retrieve the existing dates from sessionStorage
  const storedPickupDate = sessionStorage.getItem('pick_up_date_time');
  const storedDropoffDate = sessionStorage.getItem('drop_off_date_time');
  const pickupDate = storedPickupDate ? new Date(storedPickupDate) : null;
  const dropoffDate = storedDropoffDate ? new Date(storedDropoffDate) : null;

  // Check conditions based on the name
  if (name === 'pick_up_date_time') {
      if (dropoffDate && new Date(date) >= dropoffDate) {
          openDialog("Error: Pickup date must be earlier than dropoff date.");
          return; // Exit the function without updating the state
      }
      if (dropoffDate && new Date(date).getTime() === dropoffDate.getTime()) {
          openDialog("Error: Pickup date must not be the same as dropoff date.");
          return; // Exit the function without updating the state
      }
  }

  if (name === 'drop_off_date_time') {
      if (pickupDate && new Date(date) <= pickupDate) {
          openDialog("Error: Dropoff date must be later than pickup date.");
          return; // Exit the function without updating the state
      }
      if (pickupDate && new Date(date).getTime() === pickupDate.getTime()) {
          openDialog("Error: Dropoff date must not be the same as pickup date.");
          return; // Exit the function without updating the state
      }
  }

  // Update sessionStorage
  sessionStorage.setItem(name, date);
  // Update the state
  setFilterValue({
      ...filterValue,
      [name]: date
  });

  // Check if both dates are available and call handleSeach if so
  if (filterValue['pick_up_date_time'] && filterValue['drop_off_date_time']) {
    if(handleSeach())
    {
      handleSeach();

    }
  }
};

