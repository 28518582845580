import React, { useState, useContext, useEffect } from "react";
import appleAppImage from "../image/png/apple_app.png";
import googleAppImage from "../image/png/google_app.png";
import { useNavigate } from "react-router-dom";
import LocationSearch from "../ReusableComponent/LocationSearch";
import { DataContext } from "../ContextApi";
import { handleDate } from "../ReusableComponent/OverAllFilter";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import pickup from ".././image/png/pickup.svg";
import drop from ".././image/png/drop.svg";
import services from ".././image/svg/service.svg";
import region from ".././image/svg/region.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, startOfToday } from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { styled } from "@mui/system";

// const CustomDateTimePicker = styled(DateTimePicker)({
//   "& .MuiOutlinedInput-root": {
//     height: "72px",
//     overflow: "hidden",

//     paddingLeft: "13px",
//     paddingRight: "20px",

//     "& input": {
//       height: "100%",
//     },
//     "& fieldset": {
//       borderColor: "#bdbdbd !important",
//       borderRadius: "48px !important",
//       border: "2px solid #bdbdbd !important",
//       // Default border color
//     },
//     "&:hover fieldset": {
//       borderColor: "#bdbdbd !important",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#ffb400 !important",
//     },
//   },
// });
// dayjs.extend(customParseFormat);
const AfterHeader = () => {
  const {
    filterValue,
    rentYourCar,
    setFilterValue,formData, setFormData,
    openDialog,
    checkboxValues,        handleRegionChange,handleCarServiceChange

  } = useContext(DataContext);

  const [inputValueEnter, setInputValuesEnter] = useState("");
  const parseDateTime = (dateTime) => {
    return dayjs(dateTime, "YYYY-MM-DD HH:mm");
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
 
console.log("formDatanew",formData?.carService)
  // Handler for updating formData for carService select


  useEffect(() => {
    const storedPickDateTime = sessionStorage.getItem("pick_up_date_time");
    const storedDropDateTime = sessionStorage.getItem("drop_off_date_time");

    if (storedPickDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        pick_up_date_time: storedPickDateTime,
      }));
    }
    if (storedDropDateTime) {
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        drop_off_date_time: storedDropDateTime,
      }));
    }
    const location = sessionStorage.getItem("location");
    if (location) {
      // Parse the JSON string back into an object
      const value = JSON.parse(location);
      setFilterValue((prevFilterValue) => ({
        ...prevFilterValue,
        lat: value.latLng.lat,
        lng: value.latLng.lng,
      }));
    }
  }, []);
  return (
    // AfterHeader
    <div class="search-hero-container">
      <div class="container">
        <div class="c-flex">
          <div class="search-form">
            <div class="search_hero__content__wrapper">
              <div class="search_hero__title">
                {t("rent_a_car")}
                <br />
                {t("just_few_tabs")}
              </div>
              <p class="search_hero__subtitle">
                <em>{t("unlock_cars")}</em> {t("with_phone")}
              </p>
              <div
                class="js_search_form"
                // id="new_order"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if(formData?.carService==="1")
                    {
                      navigate("/search_driver");

                    }
                    else
                    {
                      navigate("/search");

                    }
                  }}
                >
                  <div class="search_form">
                    <div class="search_form__params">
                      <div class="search_form__address">
                        <div class="js_AddressAutocomplete-container">
                          <div class="Address-autocomplete">
                            <div
                              style={{ marginBottom: "0px",justifyContent:"normal",position:"relative" }}
                              class="owner_homepage_hero_estimation_form__container js_car_model_estimation_form__container"
                            >
                              <select
                                style={{
                                  height: "72px ",
                                  borderRadius: "36px 0px 0px 36px ",
                                  border: "2px solid #bdbdbd ",
                                  borderRight: "none",
                                  boxShadow: "none ",
                                  paddingLeft: "46px ",
                                  paddingRight: "46px ",
                                  backgroundColor: "#f7f7f7 ",
                                }}
                                name="carBrand"
                                id="car_model_estimation_car_brand_id"
                                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                data-url-for-models="/car_models/models"
                                onChange={handleCarServiceChange}
                                value={formData.carService} // Bind value to state
                              >
                                <option value="">Select Service </option>
                                {rentYourCar?.services?.map((val) => (
                                  <option value={val?.service_id}>
                                    {val?.service_name}
                                  </option>
                                ))}
                              </select>
                              <div style={{
                                left:"10px",bottom:"15px"
                              
                              }}   class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div class="cobalt-bg-icon">
                                  <img
                                    src={services}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginLeft: "10px",
                                    }}
                                    alt="pickup"
                                  />
                                </div>
                              </div>
                              <select
                                name="carBrand"
                                id="car_model_estimation_car_brand_id"
                                class="owner_homepage_hero_estimation_form__item c-mb-sm js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                data-url-for-models="/car_models/models"
                                style={{
                                  height: "72px",
                                  borderRadius: "0px 36px 36px 0px",
                                  position:'relative',
                                  border: "2px solid #bdbdbd",
                                  boxShadow: "none",
                                  paddingLeft: "46px",
                                  paddingRight: "46px",
                                  backgroundColor: "#f7f7f7",
                                }}
                                onChange={handleRegionChange}
                                value={formData.region_id || ''} // Bind to the region's id
                                >
                                <option value="">Select Region</option>

                                {rentYourCar?.state?.map((val) => (
                                  <option value={val?.id}>{val?.name}</option>
                                ))}
                              </select>
                              <div style={{
                                right:"0",bottom:"17px"
                              
                              }}  class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div class="cobalt-bg-icon">
                                  <img
                                    src={region}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginLeft: "10px",
                                    }}
                                    alt="pickup"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div class="cobalt-TextField cobalt-TextField--withIcon">
                              <LocationSearch
                                setInputValuesEnter={setInputValuesEnter}
                                addPickDrop={true}
                                formData={formData}
                              />
                              <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div class="cobalt-bg-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M12.825 21.325C13.076 21.073 19 15.092 19 10c0-3.86-3.14-7-7-7s-7 3.14-7 7c0 5.091 5.922 11.073 6.175 11.325a1.165 1.165 0 0 0 1.65 0M15.5 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "none" }} className="car_dropdown">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              padding: "5px 0 10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox_dropoff"
                              value="dropoff"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            <label
                              for="checkbox_dropoff"
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                fontWeight: "500",
                              }}
                            >
                              With driver
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              padding: "5px 0 10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox_dropoff"
                              value="dropoff"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            <label
                              for="checkbox_dropoff"
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                fontWeight: "500",
                              }}
                            >
                              Without driver
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              padding: "5px 0 10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox_pickup"
                              value="pickup"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            <label
                              for="checkbox_pickup"
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                fontWeight: "500",
                              }}
                            >
                              Airport Pickup
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", padding: "5px 0 0px" }}
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox_dropoff"
                              value="dropoff"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            <label
                              for="checkbox_dropoff"
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                fontWeight: "500",
                              }}
                            >
                              Airport Dropoff
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        class={
                          inputValueEnter
                            ? "search_form__dates after-location-added js_search_form__dates"
                            : "search_form__dates hidden_order_form_fields js_search_form__dates"
                        }
                      >
                        <div class="datetime_range_wrapper js_datetime_range">
                          <div class="datetime_range_input js_datetime_range_input is_layout_home">
                            <div
                              style={{ position: "relative" }}
                              className="custom-datepicker-wrapper"
                            >
                              <DatePicker
                                selected={
                                  filterValue?.pick_up_date_time
                                    ? new Date(filterValue.pick_up_date_time)
                                    : null
                                }
                                onChange={(e) => {
                                  handleDate(
                                    e,
                                    setFilterValue,
                                    filterValue,
                                    "pick_up_date_time",
                                    openDialog
                                  );
                                  // handleSeach() // Uncomment if needed
                                }}
                                minDate={startOfToday()}
                                showTimeSelect
                                placeholderText="Pickup"
                                excludeTimes={[
                                  setHours(setMinutes(new Date(), 0), 17),
                                  setHours(setMinutes(new Date(), 30), 18),
                                  setHours(setMinutes(new Date(), 30), 19),
                                  setHours(setMinutes(new Date(), 30), 17),
                                ]}
                                dateFormat="MMM d, yyyy h:mm aa"
                                popperPlacement="bottom-start" // Ensures calendar opens at the right place
                                portalId="root-portal" // Ensure you have a div with this id in your HTML
                              />
                              <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div class="cobalt-bg-icon">
                                  <img
                                    src={pickup}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginLeft: "10px",
                                    }}
                                    alt="pickup"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ position: "relative" }}
                              className="custom-datepicker-wrapper"
                            >
                              <DatePicker
                                selected={
                                  filterValue?.drop_off_date_time
                                    ? new Date(filterValue.drop_off_date_time)
                                    : null
                                }
                                minDate={
                                  filterValue?.pick_up_date_time
                                    ? new Date(filterValue.pick_up_date_time)
                                    : new Date()
                                }
                                onChange={(newValue) => {
                                  handleDate(
                                    newValue,
                                    setFilterValue,
                                    filterValue,
                                    "drop_off_date_time",

                                    openDialog
                                  );
                                }}
                                popperPlacement="bottom-start" // Ensures calendar opens at the right place
                                portalId="root-portal" // Ensure you have a div with this id in your HTML
                                disabled={
                                  // !filterValue?.pick_up_date_time ||
                                  formData?.carService==="4"|| formData?.carService==="5"
                                }
                                showTimeSelect
                                placeholderText="Return"
                                excludeTimes={[
                                  setHours(setMinutes(new Date(), 0), 17),
                                  setHours(setMinutes(new Date(), 30), 18),
                                  setHours(setMinutes(new Date(), 30), 19),
                                  setHours(setMinutes(new Date(), 30), 17),
                                ]}
                                dateFormat="MMM d, yyyy h:mm aa"
                              />
                              <div class="cobalt-TextField__Icon cobalt-Icon c-flex c-items-center">
                                <div class="cobalt-bg-icon">
                                  <img
                                    src={drop}
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginLeft: "10px",
                                    }}
                                    alt="drop"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="search_form__action">
                      <button
                        type="submit"
                        class="cob-Button cob-Button--large cob-Button__default cob-Button__default--primary js_search_form_submit"
                      >
                        {t("search_button")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="search_hero__insurance">
                <div>
                  <h1>{t("car_rental_by_localswith")}</h1>
                </div>
              </div>

              <div class="third-parties-rating">
                <div class="c-flex c-gap-xs c-mb-sm c-justify-center md:c-justify-start">
                  <a
                    target="_blank"
                    rel="noopener"
                    class="mobile-app-section__app_badge js_mobile-app-section__app_badge"
                    href=""
                  >
                    <img
                      width="120"
                      height="40"
                      alt="download"
                      src={appleAppImage}
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener"
                    class="mobile-app-section__app_badge js_mobile-app-section__app_badge"
                    href=""
                  >
                    <img
                      width="137"
                      height="40"
                      alt="download"
                      src={googleAppImage}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="homepage__hero-column">
            <div class="homepage__hero-container">
              <div class="homepage__hero"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterHeader;

