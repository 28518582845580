import React, { useEffect, useState } from "react";

const EditVehicleInsurance = ({id,formData}) => {
  const [formDataNew, setFormDataNew] = useState({
    insurance_date: "",
    insurance_expiry_date: "",
    insurance_issue_date: "",
  });
  useEffect(() => {
    setFormDataNew({
     insurance_date:formData?. insurance_date
     ,
     insurance_expiry_date:formData?. insurance_expiry_date,
     insurance_issue_date:formData?. insurance_issue_date,
    })
     }, [formData])
     const handleInputChange = (e) => {
       const { name, value } = e.target;
       setFormDataNew({
         ...formDataNew,
         [name]: value,
       });
     };
     console.log(" Date of issuance",formData)
  return (
    <>
      <div className="edit-form">
        <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
          Date{" "}
        </label>
        <input
          type="date"
          name="insurance_date"
             value={formDataNew?.insurance_date}
             onChange={handleInputChange}
          style={{ paddingLeft: "8px" }}
          placeholder="Enter Date"
        />
      </div>
      <div className="edit-form">
        <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
          Date of issuance
        </label>
        <input
          type="date"
          name="insurance_issue_date"
           value={formDataNew?.insurance_issue_date}
             onChange={handleInputChange}
          style={{ paddingLeft: "8px" }}
          placeholder="Enter Date of issuance"
        />
      </div>
      <div style={{width:"100%"}} className="edit-form">
        <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
          Expiration date
        </label>
        <input
          type="date"
          name=" insurance_expiry_date"
           value={formDataNew.insurance_expiry_date}
           onChange={handleInputChange}
          style={{ paddingLeft: "8px" }}
          placeholder="Enter Expiration date"
        />
      </div>
      <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div>
    </>

  );
};

export default EditVehicleInsurance;
