import React, { useContext, useState } from "react";
import { ImageUploader, ImageUploads } from "../../../../api";
import { DataContext } from "../../../../ContextApi";
import ImageUpload from "../ImageUplaod";
const DriverImageUpload = () => {
  const { imageURLs, setImageURLs, openDialog ,selectDriverCase, setSelectDriverCase} = useContext(DataContext);
  const [isUploading, setIsUploading] = useState("");
console.log("selectDriverCase",selectDriverCase)
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const name =event.target.name
    console.log("namer",name)
    if (!file) return;
    if (imageURLs.length > 6) {
      openDialog("Max Image seven Allowed");
      return;
    }
    const formData = new FormData();
    formData.append("file_name", file);

    setIsUploading(name);
    ImageUploader(formData,name, setSelectDriverCase, setIsUploading,selectDriverCase);
  };
  const handleImageDelete = (index) => {
    const updatedImageURLs = imageURLs?.filter((_, i) => i !== index);
    setImageURLs(updatedImageURLs);
  };
  return (
    <div class="pricing-education-seasonality">
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Main picture</div>
        <div class="c-mb-lg" style={{ marginBottom: "0px" }}>
          <div
            class="cobalt-FormField"
            data-form-field-method="phone_country_phone_number_national"
          >
            <div class="cobalt-ComposedFormField">
              <div class="js_car_picture_uploader">
                <div class="c-mb-md" style={{ marginBottom: "0px" }}>
                  <div class="js_car_picture_uploader_empty">
                    <div class="row">
                      <div class="col-xs-6 col-md-6">
                        <div class="car_picture_uploader__wrapper">
                          <label class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                            <input
                              class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                              name="identityPhoto"
                              type="file"
                              onChange={handleImageUpload}
                            />
                            <div class="car_picture_uploader__placeholder_content">
                              <div class="c-text-body-md c-text-subdued c-mb-xs">
                                Identity Photo
                              </div>
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </label>
                          {isUploading=="identityPhoto" && !selectDriverCase?.identityPhoto && (
                            <div
                              class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_spinner"
                              hidden=""
                            >
                              <div class="car_picture_uploader__placeholder_content">
                                <div class="cobalt-Spinner">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="row">
                       { selectDriverCase?.identityPhoto     &&  
                                            <div
                              class="col-xs-6 col-md-4"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                onClick={() => handleImageDelete(0)}
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  right: "8px",
                                  background: "#fff",
                                  boxShadow: "0 0 8px #ddd",
                                  borderRadius: "10px",
                                  height: "35px",
                                  width: "35px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  viewBox="0 0 512 512"
                                >
                                  <g>
                                    <path
                                      fill="#fc0005"
                                      fill-rule="evenodd"
                                      d="M170.8 14.221A14.21 14.21 0 0 1 185 .014L326.991.006a14.233 14.233 0 0 1 14.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 0 1-21.856 20.33H127.954a21.968 21.968 0 0 1-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0 1 84.176 78.2l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0V233.816a14.42 14.42 0 0 0-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0V233.816a14.422 14.422 0 0 0-28.843-.01z"
                                      opacity="1"
                                      data-original="#fc0005"
                                      class=""
                                    ></path>
                                  </g>
                                </svg>
                              </div>

                              <a
                                class="car_picture_uploader__description_image to_right"
                                data-modalid="js_see_example_car_picture_front_popin"
                                href=""
                                data-modal-inited="true"
                              >
                                <img
                                  src={selectDriverCase?.identityPhoto}
                                  style={{
                                    border: "1px solid #ddd",
                                    borderRadius: "10px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                />
                              </a>
                            </div>
                       }
                         
                        </div>
                      </div>

                      <div class="col-xs-6 col-md-6">
                        <div class="car_picture_uploader__wrapper">
                          <label class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                            <input
                              class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                              name="licenseFrontPhoto"
                              type="file"
                              onChange={handleImageUpload}
                            />
                            <div class="car_picture_uploader__placeholder_content">
                              <div class="c-text-body-md c-text-subdued c-mb-xs">
                                License Front Photo
                              </div>
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </label>
                            {isUploading=="licenseFrontPhoto" && !selectDriverCase?.licenseFrontPhoto && (

                            <div
                              class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_spinner"
                              hidden=""
                            >
                              <div class="car_picture_uploader__placeholder_content">
                                <div class="cobalt-Spinner">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="row">
                         
                          {selectDriverCase?.licenseFrontPhoto&&
                            <div
                              class="col-xs-6 col-md-4"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                onClick={() => handleImageDelete(1)}
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  right: "8px",
                                  background: "#fff",
                                  boxShadow: "0 0 8px #ddd",
                                  borderRadius: "10px",
                                  height: "35px",
                                  width: "35px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  viewBox="0 0 512 512"
                                >
                                  <g>
                                    <path
                                      fill="#fc0005"
                                      fill-rule="evenodd"
                                      d="M170.8 14.221A14.21 14.21 0 0 1 185 .014L326.991.006a14.233 14.233 0 0 1 14.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 0 1-21.856 20.33H127.954a21.968 21.968 0 0 1-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0 1 84.176 78.2l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0V233.816a14.42 14.42 0 0 0-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0V233.816a14.422 14.422 0 0 0-28.843-.01z"
                                      opacity="1"
                                      data-original="#fc0005"
                                      class=""
                                    ></path>
                                  </g>
                                </svg>
                              </div>

                              <a
                                class="car_picture_uploader__description_image to_right"
                                data-modalid="js_see_example_car_picture_front_popin"
                                href=""
                                data-modal-inited="true"
                              >
                                <img
                                  src={selectDriverCase?.licenseFrontPhoto}
                                  style={{
                                    border: "1px solid #ddd",
                                    borderRadius: "10px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                />
                              </a>
                            </div>
                          }
                         
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        class="col-xs-12 col-md-12"
                      >
                        <div class="car_picture_uploader__wrapper">
                          <label class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                            <input
                              class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                              name="licenseBackPhoto"
                              type="file"
                              onChange={handleImageUpload}
                            />
                            <div class="car_picture_uploader__placeholder_content">
                              <div class="c-text-body-md c-text-subdued c-mb-xs">
                                License Back Photo
                              </div>
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </label>
                            {isUploading=="licenseBackPhoto" && !selectDriverCase?.licenseBackPhoto && (

                            <div
                              class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_spinner"
                              hidden=""
                            >
                              <div class="car_picture_uploader__placeholder_content">
                                <div class="cobalt-Spinner">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="row">
                          {/* {imageURLs?.map((val,index)=>( */}

                          {/* {imageURLs.length === 3 && ( */}
                          {selectDriverCase?.licenseBackPhoto&&
                            <div
                              class="col-xs-6 col-md-4"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                onClick={() => handleImageDelete(2)}
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  right: "8px",
                                  background: "#fff",
                                  boxShadow: "0 0 8px #ddd",
                                  borderRadius: "10px",
                                  height: "35px",
                                  width: "35px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  width="20"
                                  height="20"
                                  x="0"
                                  y="0"
                                  viewBox="0 0 512 512"
                                >
                                  <g>
                                    <path
                                      fill="#fc0005"
                                      fill-rule="evenodd"
                                      d="M170.8 14.221A14.21 14.21 0 0 1 185 .014L326.991.006a14.233 14.233 0 0 1 14.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 0 1-21.856 20.33H127.954a21.968 21.968 0 0 1-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0 1 84.176 78.2l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0V233.816a14.42 14.42 0 0 0-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0V233.816a14.422 14.422 0 0 0-28.843-.01z"
                                      opacity="1"
                                      data-original="#fc0005"
                                      class=""
                                    ></path>
                                  </g>
                                </svg>
                              </div>

                              <a
                                class="car_picture_uploader__description_image to_right"
                                data-modalid="js_see_example_car_picture_front_popin"
                                href=""
                                data-modal-inited="true"
                              >
                                <img
                                  src={selectDriverCase?.licenseBackPhoto}
                                  style={{
                                    border: "1px solid #ddd",
                                    borderRadius: "10px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                />
                              </a>
                            </div>
                          }
                          {/* )} */}
                          {/* ))} */}
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-12">
                        <div class="row car_picture_uploader__description_container">
                          <div
                            class="col-xs-6 col-md-12"
                            style={{ marginBottom: "15px" }}
                          >
                            <div class="car_picture_uploader__description_text">
                              <p>
                                <b>A front photo</b>
                                that stands out: it is the first one drivers
                                see.
                              </p>
                            </div>
                          </div>
                          <div class="col-xs-12 col-md-12">
                            <div class="row">
                              {/* {imageURLs?.map((val,index)=>(

                          
                              <div
                                class="col-xs-6 col-md-4"
                                style={{ marginBottom: "15px" }}
                              >
                                <div
                                                                  onClick={() => handleImageDelete(index)}

                                  style={{
                                    position: "absolute",
                                    cursor:"pointer",
                                    right: "8px",
                                    background: "#fff",
                                    boxShadow: "0 0 8px #ddd",
                                    borderRadius: "10px",
                                    height: "35px",
                                    width: "35px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="20"
                                    height="20"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 512 512"
                                  >
                                    <g>
                                      <path
                                        fill="#fc0005"
                                        fill-rule="evenodd"
                                        d="M170.8 14.221A14.21 14.21 0 0 1 185 .014L326.991.006a14.233 14.233 0 0 1 14.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 0 1-21.856 20.33H127.954a21.968 21.968 0 0 1-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0 1 84.176 78.2l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0V233.816a14.42 14.42 0 0 0-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0V233.816a14.422 14.422 0 0 0-28.843-.01z"
                                        opacity="1"
                                        data-original="#fc0005"
                                        class=""
                                      ></path>
                                    </g>
                                  </svg>
                                </div>

                                <a
                                  class="car_picture_uploader__description_image to_right"
                                  data-modalid="js_see_example_car_picture_front_popin"
                                  href=""
                                  data-modal-inited="true"
                                >
                                  <img
                                    src={val}
                                    style={{
                                      border: "1px solid #ddd",
                                      borderRadius: "10px",
                                      height: "80px",
                                      objectFit: "contain",
                                    }}
                                  />
                                </a>
                              </div>
                            ))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverImageUpload;
