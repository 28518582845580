import React, { useState } from "react";
import dropDown from "../image/svg/dropdownblack.svg";
import dropDownClose from "../image/svg/closeDropDown.svg";

const ReusableFaq = () => {
  const [faqItems, setFaqItems] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
  });
  const toggleItem = (item) => {
    setFaqItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  return (
    <>
      <section
        style={{ paddingBottom: "60px" }}
        class="home_section home_section--flat"
      >
        <div class="home_section__container">
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "27px",
            }}
            class="c-text-title-lg "
          >
            FAQ
          </div>
        </div>
      </section>

      <section
        style={{ paddingBottom: "60px" }}
        className="home_section home_section--flat"
      >
        <div className="home_section__container">
          <div className="faq expandable">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item1")}
                className={`faqItem ${faqItems.item1 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is overlo?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item1 ? dropDownClose : dropDown}
                />
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item1 ? "block" : "none" }}
                >
                  <p>
                    Getaround est une plateforme leader mondial de l’autopartage
                    en boucle qui opère en France depuis 2009. Nous mettons en
                    relation des propriétaires de véhicules (professionnels ou
                    particuliers) avec des locataires.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8  ",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item2")}
                className={`faqItem ${faqItems.item2 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item2 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item2 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work est un service d’autopartage dédié aux
                    entreprises ou associations de toutes tailles.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item3")}
                className={`faqItem ${faqItems.item3 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is Getaround Connect?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item3 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item3 ? "block" : "none" }}
                >
                  <p>
                    Notre solution Getaround Connect vous permet de
                    déverrouiller le véhicule directement depuis votre
                    smartphone via un parcours 100% digital. Pas besoin de
                    rencontrer le propriétaire de la voiture.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item4")}
                className={`faqItem ${faqItems.item4 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  Do I need to create a new Overlo account?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item4 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item4 ? "block" : "none" }}
                >
                  <p>
                    La création d'un nouveau compte n'est pas nécessaire. Nous
                    créons l'espace entreprise à partir d'un compte Getaround
                    déjà existant.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item5")}
                className={`faqItem ${faqItems.item5 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  Are there any fees associated with using Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item5 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item5 ? "block" : "none" }}
                >
                  <p>
                    L'ouverture et la gestion de votre compte est 100% gratuit.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item6")}
                className={`faqItem ${faqItems.item6 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  In which countries/cities is Getaround for Work available?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item6 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item6 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work est disponible partout en France
                    métropolitaine.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  borderBottom: "1px solid #E7E7E8 ",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item7")}
                className={`faqItem ${faqItems.item7 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  My company is headquartered in a foreign country but operates
                  in France?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item7 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item7 ? "block" : "none" }}
                >
                  <p>
                    Non, votre entreprise doit être enregistrée auprès de
                    l’administration française et posséder un numéro SIRET.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  borderBottom: "1px solid #E7E7E8 ",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item8")}
                className={`faqItem ${faqItems.item8 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What types of vehicles are available on Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item8 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item8 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work propose un large choix de véhicules, de
                    la petite citadine à l'utilitaire de 12m3, pour répondre aux
                    besoins des déplacements professionnels des entreprises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReusableFaq;
